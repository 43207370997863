import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./JobList.css";
import config from "../../include/config";

const JobList = ({ searchQuery = "", filters = {} }) => {
  const [jobs, setJobs] = useState([]);
  const [expandedJobs, setExpandedJobs] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get(`${config.BASE_URL}/api/jobs/getAllJob`);
        setJobs(response.data || []);
      } catch (error) {
        console.error("Error fetching jobs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchJobs();
  }, []);

  // Memoized Filtering Logic
  const filteredJobs = useMemo(() => {
    return jobs.filter((job) => {
      const title = job.title?.toLowerCase() || "";
      const location = job.location?.toLowerCase() || "";
      const jobType = job.jobType || "";

      // Check if it matches the search query
      const matchesSearch =
        title.includes(searchQuery.toLowerCase()) ||
        location.includes(searchQuery.toLowerCase()) ||
        jobType.toLowerCase().includes(searchQuery.toLowerCase());

      // Check if it matches the selected dropdown filter
      const matchesJobType =
        !filters.jobType || // Show all jobs if no filter selected
        (filters.jobType === "Remote" && location.includes("remote")) ||
        (filters.jobType === "FullTime" && jobType === "FullTime") ||
        (filters.jobType === "PartTime" && jobType === "PartTime") ||
        (filters.jobType === "Internship" && jobType === "Internship");

      return matchesSearch && matchesJobType;
    });
  }, [jobs, searchQuery, filters]);

  // Handle Apply Button Click
  const handleApplyClick = (job) => {
    const isLoggedIn = localStorage.getItem("accessToken");
    if (isLoggedIn) {
      navigate("/apply", { state: { job } });
    } else {
      navigate("/LoginSignup", { state: { redirectTo: "/apply", job } });
    }
  };

  // Toggle Job Description Expand/Collapse
  const toggleExpanded = (jobId) => {
    setExpandedJobs((prev) => ({
      ...prev,
      [jobId]: !prev[jobId],
    }));
  };

  // Helper function to check if a job is expired
  const isJobExpired = (createdAt) => {
    if (!createdAt) return false;
    const jobDate = new Date(createdAt);
    return (new Date() - jobDate) / (1000 * 60 * 60 * 24) > 15;
  };

  // Get Expiration Date
  const getExpirationDate = (createdAt) => {
    if (!createdAt) return "Not specified";
    const jobDate = new Date(createdAt);
    jobDate.setDate(jobDate.getDate() + 15);
    return jobDate.toLocaleDateString();
  };

  return (
    <div className="job-list-container">
      <div className="job-list">
        {loading ? (
          <p>Loading jobs...</p>
        ) : filteredJobs.length === 0 ? (
          <p>No job openings match your filters.</p>
        ) : (
          filteredJobs.map((job) => {
            const isExpanded = expandedJobs[job.id] || false;
            const expired = isJobExpired(job.createdAt);
            const expiredOn = getExpirationDate(job.createdAt);

            return (
              <div key={job.id} className={`job-card ${expired ? "expired" : ""}`}>
                {/* Job Title */}
                <h3>{job.title || "Untitled Job"}</h3>
                <p><strong>Company:</strong> {job.company || "Encoders Software PVT LTD"}</p>
                <p><strong>Location:</strong> {job.location || "Not specified"}</p>
                <p><strong>Job Type:</strong> {job.jobType || "Not specified"}</p>
                <p><strong>Posted On:</strong> {job.createdAt ? new Date(job.createdAt).toLocaleDateString() : "Not specified"}</p>
                <p><strong>Expired On:</strong> {expired ? expiredOn : "Not expired yet"}</p>
                {expired && <p className="expired-label">Job Expired</p>}

                {/* Job Description */}
                <div className={`job-description ${isExpanded ? "expanded" : ""}`}>
                  <ul>
                    {(job.description || "").split("\n").map((line, index) => (
                      <li key={index}>⚡ {line}</li>
                    ))}
                  </ul>
                </div>
                <span onClick={() => toggleExpanded(job.id)} className="toggle-description">
                  {isExpanded ? "Read Less ▲" : "Read More ▼"}
                </span>

                {/* Apply Button */}
                <button onClick={() => handleApplyClick(job)} className="apply-button" disabled={expired}>
                  {expired ? "Expired" : "Apply Now"}
                </button>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default JobList;
