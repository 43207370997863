import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./navbar.css";


const Navbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    setIsLoggedIn(!!token);
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    setIsLoggedIn(false);
    navigate("/");
  };

  // Navigate to contact and scroll to the form
  

  return (
    <header>
      {/* Top Bar */}
      <div className="top-bar bg-white py-2 shadow-sm">
        <div className="container d-flex justify-content-between align-items-center">
          {/* Contact Info */}
          <div className="contact-info d-flex align-items-center">
            <div className="me-4 d-flex align-items-center">
              <i className="fa fa-phone me-2" style={{ color: "blue" }}></i>
              <a href="tel:+917258803502" className="text-decoration-none" style={{ color: "black" }}>
                +91-7258803502
              </a>
            </div>
            <div className="d-flex align-items-center">
              <i className="fa fa-envelope me-2" style={{ color: "blue" }}></i>
              <a href="mailto:support@encoderssoftware.com" className="text-decoration-none" style={{ color: "black" }}>
                support@encoderssoftware.com
              </a>
            </div>
          </div>

          {/* Blinking Quotation Icon (Fixed to Right) */}
       
        </div>
      </div>

      {/* Navigation Bar */}
      <nav className="navbar navbar-expand-lg navbar-light bg-white">
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img src="/logo.png" alt="Encoders Software Pvt Ltd" height="50" />
          </Link>

          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item"><Link to="/" className="nav-link">Home</Link></li>
              <li className="nav-item"><Link to="/about" className="nav-link">About Us</Link></li>
              <li className="nav-item"><Link to="/contact" className="nav-link">Contact Us</Link></li>
              <li className="nav-item"><Link to="/Careers" className="nav-link">Careers</Link></li>
              <li className="nav-item"><Link to="/BlogsUsers" className="nav-link">Blogs</Link></li>
              <li className="nav-item"><Link to="/training-course" className="nav-link">Training Program</Link></li>

              {isLoggedIn && (
                <li className="nav-item"><Link to="/HomePage" className="nav-link">Menu</Link></li>
              )}

              <li className="nav-item ms-3">
                {isLoggedIn ? (
                  <button onClick={handleLogout} className="btn btn-outline-danger">
                    <i className="fa fa-sign-out me-2"></i> Logout
                  </button>
                ) : (
                  <Link to="/LoginSignup" className="btn btn-primary">
                    <i className="fa fa-user me-2"></i> Login
                  </Link>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
