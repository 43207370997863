const config = {
   
  BASE_URL: "https://api.encoderssoftware.com",
  // BASE_URL: "http://localhost:9092",
   AUTH_URL: "https://api.encoderssoftware.com",
   
   
  //AUTH_URL: "http://localhost:9092",
  
 
  };
  
  export default config;
  