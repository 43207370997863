import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './DataTable.css';

const DataTable = ({ data, selectedColumns }) => {
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  if (!data || data.length === 0) {
    return <div className="data-table-empty">No data available</div>;
  }

  // Sorting Logic
  const handleSort = (col) => {
    const newDirection = sortColumn === col && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortColumn(col);
    setSortDirection(newDirection);
  };

  const sortedData = [...data].sort((a, b) => {
    if (!sortColumn) return 0;
    const valueA = a[sortColumn] || '';
    const valueB = b[sortColumn] || '';

    return sortDirection === 'asc'
      ? String(valueA).localeCompare(String(valueB))
      : String(valueB).localeCompare(String(valueA));
  });

  return (
    <div className="data-table-container">
      <table className="data-table">
        <thead>
          <tr>
            {selectedColumns.map((col) => (
              <th key={col} onClick={() => handleSort(col)} className="sortable">
                {col.replace(/([A-Z])/g, ' $1').trim()}
                {sortColumn === col && (sortDirection === 'asc' ? ' 🔼' : ' 🔽')}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {selectedColumns.map((col) => (
                <td key={`${rowIndex}-${col}`}>
                  {row[col] !== null && row[col] !== undefined ? row[col] : '-'}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

DataTable.propTypes = {
  data: PropTypes.array.isRequired,
  selectedColumns: PropTypes.array.isRequired,
};

export default DataTable;
