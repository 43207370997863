import React from 'react';
import { FaSyncAlt, FaFilePdf, FaFileExcel, FaFileCsv, FaFileCode } from 'react-icons/fa';
import './AdminDashboard.css';

const AdminControls = ({
  fetchData,
  statusFilter,
  setStatusFilter,
  updateAtFilter,
  setUpdateAtFilter,
  exitEmploymentFilter,
  setExitEmploymentFilter,
  selectionStatusFilter,
  setSelectionStatusFilter,
  offerFlagFilter,
  setOfferFlagFilter,
  selectedColumns,
  setSelectedColumns,
  allColumns,
  downloadCSV,
  downloadPDF,
  downloadExcel,
  downloadJSON,
  graphTypes,
  selectedGraphs,
  setSelectedGraphs,
}) => {
  return (
    <div className="admin-controls">
      {/* Data Load Button */}
      <div className="control-section">
        <button onClick={fetchData} className="action-button refresh">
          <FaSyncAlt /> Load Data
        </button>
        <button onClick={() => {
          setStatusFilter('');
          setUpdateAtFilter('');
          setExitEmploymentFilter('');
          setSelectionStatusFilter('');
          setOfferFlagFilter('');
        }} className="action-button reset">
          Reset Filters
        </button>
      </div>

      {/* Status Filter */}
      <div className="control-section">
        <label>Status:</label>
        <select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
          <option value="">-- Select --</option>
          <option value="PENDING">Pending</option>
          <option value="ACCEPTED">Accepted</option>
          <option value="DECLINED">Declined</option>
        </select>
      </div>

      {/* Updated At Filter */}
      <div className="control-section">
        <label>Date Wise:</label>
        <input
          type="date"
          value={updateAtFilter}
          onChange={(e) => setUpdateAtFilter(e.target.value)}
        />
      </div>

      {/* Exit Employment Filter */}
      <div className="control-section">
        <label>Exit Employment:</label>
        <select value={exitEmploymentFilter} onChange={(e) => setExitEmploymentFilter(e.target.value)}>
          <option value="">-- Select --</option>
          <option value="1">Resigned</option>
          <option value="0">No</option>
        </select>
      </div>

      {/* Selection Status Filter */}
      <div className="control-section">
        <label>Selection Status:</label>
        <select value={selectionStatusFilter} onChange={(e) => setSelectionStatusFilter(e.target.value)}>
          <option value="">-- Select --</option>
          <option value="1">Interview Selected</option>
          <option value="0">Rejected</option>
        </select>
      </div>

      {/* Offer Flag Filter */}
      <div className="control-section">
        <label>Offer Flag:</label>
        <select value={offerFlagFilter} onChange={(e) => setOfferFlagFilter(e.target.value)}>
          <option value="">-- Select --</option>
          <option value="1">Offer Generated</option>
          <option value="0">Not Generated</option>
        </select>
      </div>

      {/* Graph Selector */}
      <div className="control-section">
        <label>Select Graphs:</label>
        {graphTypes.map((type) => (
          <label key={type} className="checkbox">
            <input
              type="checkbox"
              checked={selectedGraphs.includes(type)}
              onChange={() =>
                setSelectedGraphs((prev) =>
                  prev.includes(type) ? prev.filter((g) => g !== type) : [...prev, type]
                )
              }
            />
            {type}
          </label>
        ))}
      </div>

      {/* Export Buttons */}
      <div className="control-section export-buttons">
        <button onClick={downloadCSV} className="action-button csv">
          <FaFileCsv /> Export CSV
        </button>
        <button onClick={downloadPDF} className="action-button pdf">
          <FaFilePdf /> Export PDF
        </button>
        <button onClick={downloadExcel} className="action-button excel">
          <FaFileExcel /> Export Excel
        </button>
        <button onClick={downloadJSON} className="action-button json">
          <FaFileCode /> Export JSON
        </button>
      </div>
    </div>
  );
};

export default AdminControls;
