import React from 'react'
import About from './components/pages/About'
import Contact from './components/pages/Contact'
import Home from './components/pages/Home'
import Careers from './components/pages/Careers'
import ApplyForm from './components/pages/ApplyForm'
import HomePage from './components/pages/HomePage'
import BlogsUsers from './components/pages/BlogsUsers'
import ForgotPassword from './components/pages/ForgotPassword'
import ResetPassword from './components/pages/ResetPassword'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import TermsOfUse from "./components/pages/TermsOfUse";
import ScrollToTop from './include/ScrollToTop'
import "@fortawesome/fontawesome-free/css/all.min.css";
import LoginSignup from './components/pages/LoginSignup'
import ShippingDelivery from './components/pages/ShippingDelivery'
import CancellationRefund from './components/pages/CancellationRefund'
import ActivateAccount from './components/pages/ActivateAccount'

import PaymentDetailsForm from './components/pages/PaymentDetailsForm'
import PayslipForm from './components/pages/PayslipForm'
import InterviewStatusForm from './components/pages/InterViewStatusForm'
import CourseManagement from './components/pages/CourseManagement'
import TrainingCourse from './components/pages/TrainingCourse'

import MerchantPay from './components/pages/MerchantPay'

import DiscountGenerator from './components/pages/DiscountGenerator'


import PaymentDetails from './components/pages/PaymentDetails'
import { ImageCacheProvider } from "./components/pages/ImageCacheContext";






const App = () => {

 
  return (

    <ImageCacheProvider>
    <Router>
      <div>
     
      <ScrollToTop></ScrollToTop>

     <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/training-course" element={<TrainingCourse />} />
          <Route path="/about" element={<About />} />
          <Route path="/Careers" element={<Careers />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/apply" element={<ApplyForm />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/LoginSignup" element={<LoginSignup />} />
          <Route path="/HomePage" element={<HomePage />} />
          <Route path="/ForgotPassword" element={<ForgotPassword />} />
          <Route path="/ResetPassword" element={<ResetPassword />} />
          <Route path="/BlogsUsers" element={<BlogsUsers />} />
          <Route path='/ShippingDelivery' element={<ShippingDelivery />} />
          <Route path='/CancellationRefund' element={<CancellationRefund />} />
          <Route path="/ActivateAccount" element={<ActivateAccount />} />
        <Route path="/PaymentDetailsForm" element={<PaymentDetailsForm />} />
        <Route path="/PayslipForm" element={<PayslipForm />} />
        <Route path="/InterviewStatusForm" element={<InterviewStatusForm />} />
        <Route Path="/CourseManagement" element={<CourseManagement />} />
        <Route path="/pay" element={<MerchantPay/>} />   
        <Route path="/generate" element={<DiscountGenerator/>} />  
        <Route path="/paymentinfo" element={<PaymentDetails/>} />  
        <Route path="/training-course/:course_id" element={<TrainingCourse />} /> 
        <Route path="/blogs/:blogId" element={<BlogsUsers />}/>
        <Route path="/blogs" element={<BlogsUsers />}/>
   
        
        

        </Routes>
      </div>

    </Router>
    </ImageCacheProvider>
  )
}

export default App
