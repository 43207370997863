import React, { useState, useEffect, useCallback } from "react";
import axiosInstance from "./axiosInstance";
import { FaEdit, FaTrash, FaPlus, FaSearch } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Blogs.css";
import { debounce } from "lodash";

function Blogs() {
  const [blogs, setBlogs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    title: "",
    content: "",
    author: localStorage.getItem("email") || "Guest",
    categoryId: "",
    image: "",
    imagePreview: null,
  });
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [imageLoading, setImageLoading] = useState({});

  // ✅ Load Blogs & Categories on Component Mount
  useEffect(() => {
    loadBlogs();
    loadCategories();
  }, []);

  // ✅ Load Blogs
  const loadBlogs = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get("/api/blogs");
      setBlogs(response.data.content || []);
    } catch (error) {
      toast.error("Failed to fetch blogs");
    }
    setLoading(false);
  };

  // ✅ Load Categories
  const loadCategories = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get("/api/blogs/categories");
      setCategories(response.data || []);
    } catch (error) {
      toast.error("Failed to fetch categories");
    }
    setLoading(false);
  };

  // ✅ Search Blogs (Debounced API Call)
  const debouncedSearch = useCallback(
    debounce(async (query) => {
      try {
        const response = await axiosInstance.get(`/api/blogs?query=${query}`);
        setBlogs(response.data.content || []);
      } catch (error) {
        toast.error("Error searching blogs");
      }
    }, 500),
    []
  );

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    debouncedSearch(e.target.value);
  };

  // ✅ Create or Update Blog
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const payload = { ...form };
      if (form.image) {
        payload.image = form.image;
      }

      if (selectedBlog) {
        await axiosInstance.put(`/api/blogs/${selectedBlog.id}`, payload);
        toast.success("Blog updated successfully!");
      } else {
        await axiosInstance.post("/api/blogs", payload);
        toast.success("Blog created successfully!");
      }

      resetForm();
      loadBlogs();
    } catch (error) {
      toast.error("Failed to save blog. Please check your inputs.");
    }
    setLoading(false);
  };

  // ✅ Delete Blog
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this blog?")) {
      setLoading(true);
      try {
        await axiosInstance.delete(`/api/blogs/${id}`);
        toast.success("Blog deleted successfully!");
        loadBlogs();
      } catch (error) {
        toast.error("Failed to delete blog. Please try again later.");
      }
      setLoading(false);
    }
  };

  // ✅ Edit Blog
  const handleEdit = (blog) => {
    setSelectedBlog(blog);
    setForm({
      title: blog.title,
      content: blog.content,
      author: blog.author || localStorage.getItem("email") || "Guest",
      categoryId: blog.categoryId ? String(blog.categoryId) : "",
      image: "",
      imagePreview: blog.image ? `/api/blogs/${blog.id}/image` : null, // ✅ Fetch Image from API
    });
    setIsFormVisible(true);
  };

  // ✅ Handle Image Upload
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setForm((prevForm) => ({
          ...prevForm,
          image: event.target.result.split(",")[1],
          imagePreview: URL.createObjectURL(file),
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  // ✅ Reset Form
  const resetForm = () => {
    setForm({
      title: "",
      content: "",
      author: localStorage.getItem("email") || "Guest",
      categoryId: "",
      image: "",
      imagePreview: null,
    });
    setSelectedBlog(null);
    setIsFormVisible(false);
  };

  return (
    <div className="blogs-container">
      <ToastContainer />

      {/* 🔎 Search Bar */}
      <div className="header">
        <div className="search-bar">
          <input type="text" placeholder="Search blogs..." value={searchQuery} onChange={handleSearchChange} />
          <button className="search-btn" disabled={loading}>
            <FaSearch /> Search
          </button>
        </div>
        <button className="add-btn" onClick={() => setIsFormVisible(true)} disabled={loading}>
          <FaPlus /> Add Blog
        </button>
      </div>

      {/* 🕑 Loading Spinner */}
      {loading && <div className="loader">Loading...</div>}

      {/* 📝 Blog Form Modal */}
      {isFormVisible && (
        <div className="form-modal-overlay">
          <div className="form-modal">
            <form onSubmit={handleSubmit}>
              <label>Title</label>
              <input type="text" value={form.title} onChange={(e) => setForm({ ...form, title: e.target.value })} required />

              <label>Content</label>
              <textarea value={form.content} onChange={(e) => setForm({ ...form, content: e.target.value })} required />

              <label>Author</label>
              <input type="text" value={form.author} readOnly />

              <label>Category</label>
              <select value={form.categoryId} onChange={(e) => setForm({ ...form, categoryId: e.target.value })} required>
                <option value="">Select Category</option>
                {categories.map((category) => (
                  <option key={category.id} value={String(category.id)}>
                    {category.name}
                  </option>
                ))}
              </select>

              <label>Image</label>
              <input type="file" accept="image/*" onChange={handleImageChange} />
              {form.imagePreview && <img src={form.imagePreview} alt="Preview" className="preview-image" />}

              <div className="form-buttons">
                <button type="submit" className="submit-btn">Save</button>
                <button type="button" className="cancel-btn" onClick={resetForm}>Cancel</button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* 🎴 Blog Cards */}
      <div className="blog-cards">
        {blogs.map((blog) => (
          <div key={blog.id} className="blog-card">
            {imageLoading[blog.id] && <div className="image-loader">Loading...</div>}
            <img
              src={`/api/blogs/${blog.id}/image`}
              alt="Blog"
              className="lazy-image"
              onLoad={() => setImageLoading((prev) => ({ ...prev, [blog.id]: false }))}
            />
            <h3>{blog.title}</h3>
            <p>{blog.content}</p>
            <div className="card-actions">
              <button onClick={() => handleEdit(blog)}><FaEdit /> Edit</button>
              <button onClick={() => handleDelete(blog.id)}><FaTrash /> Delete</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Blogs;
