import React, { useState, useEffect } from "react";
import "./ContactUs.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope, faMapMarkerAlt, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faTwitter, faLinkedin, faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import Footer from "../../include/Footer";
import axios from "axios";
import config from "../../include/config";
import Navbar from "../../include/Navbar";
import { useLocation } from "react-router-dom";

import { faPaperPlane, faRobot, faUser } from "@fortawesome/free-solid-svg-icons";

const ContactUs = () => {

  const location = useLocation();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    company: "",
    message: "",
    captchaInput: "",
  });

  const [responseMessage, setResponseMessage] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const [chatInput, setChatInput] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [captcha, setCaptcha] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});


  const quickReplies = ['Hello', 'Services', 'Support', 'Location', 'Careers'];

  useEffect(() => {
    setTimeout(() => {
      const contactForm = document.querySelector(".contact-form");
      console.log("Attempting to scroll to .contact-form...");

      if (contactForm) {
        contactForm.scrollIntoView({ behavior: "smooth", block: "start" });
      } else {
        console.error("contact-form not found!");
      }
    }, 500); // Small delay to ensure rendering completes
  }, [location]);


  useEffect(() => {
    fetchCaptcha();
    setChatMessages([
      {
        sender: 'bot',
        text: '👋 Hello! Welcome to Encoders Software Pvt Ltd. How can I assist you today?',
      },
    ]);
  }, []);

  const fetchCaptcha = async () => {
    try {
      const response = await axios.get(`${config.BASE_URL}/api/captcha/generate`);
      setCaptcha(response.data);
    } catch (error) {
      console.error("Error fetching captcha:", error);
     // alert("Failed to load captcha. Please try again.");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleChatSend();
    }
  };

 

 // Chat Logic Enhancements
 const handleChatSend = () => {
  if (!chatInput.trim()) return;

  const userMessage = { sender: 'user', text: chatInput.trim() };
  setChatMessages((prev) => [...prev, userMessage]);
  setChatInput('');
  setIsTyping(true);

  setTimeout(() => {
    const botResponse = generateBotResponse(chatInput.trim());
    const botMessage = { sender: 'bot', text: botResponse };
    setChatMessages((prev) => [...prev, botMessage]);
    setIsTyping(false);
  }, 800);
};

const handleChatKeyPress = (e) => {
  if (e.key === 'Enter') {
    e.preventDefault();
    handleChatSend();
  }
};

// Handle Quick Replies
const handleQuickReply = (reply) => {
  const userMessage = { sender: 'user', text: reply };
  setChatMessages((prev) => [...prev, userMessage]);
  setIsTyping(true);

  setTimeout(() => {
    const botResponse = generateBotResponse(reply);
    const botMessage = { sender: 'bot', text: botResponse };
    setChatMessages((prev) => [...prev, botMessage]);
    setIsTyping(false);
  }, 800);
};

// Enhanced Bot Response
const generateBotResponse = (input) => {
  const lowerInput = input.toLowerCase();

  switch (lowerInput) {
    case 'hello':
    case 'hi':
    case 'hey':
      return (
        <>
          👋 **Hello! How can I assist you today?**  
          <strong>Here are some options:</strong>
          <ul>
            <li>1️⃣ **Services 🛠️**</li>
            <li>2️⃣ **Support 📞**</li>
            <ul>
              <li>📞 Call: +91 7258803502, +91 9430796895</li>
              <li>📧 Email: support@encoderssoftware.com</li>
            </ul>
            <li>3️⃣ **Company Location 📍**</li>
          </ul>
          Type a keyword to know more!
        </>
      );

    case 'services':
      return '🛠️ We offer trending buiness  Website Development(Static and Dynamics), Mobile App Development for IOS and Android, and SaaS solutions,Goverment Project, School College Final Semester Project. Let me know if you need details!';

    case 'support':
      return '📞 Reach us at:\n- Phone: +91 7258803502, +91 9430796895\n- Email: support@encoderssoftware.com';

    case 'location':
      return '📍 Our office is located at Oak Forest Flat No 8 F, Pundag Road, Ranchi, Jharkhand 834002.';

    case 'careers':
      return '🚀 We are hiring! Check our Careers page https://encoderssoftware.com/Careers for exciting opportunities.';

      case '1':
      return '🛠️ We offer trending buiness  Website Development(Static and Dynamics), Mobile App Development for IOS and Android, and SaaS solutions,Goverment Project, School College Final Semester Project. Let me know if you need details!';

    case '2':
      return '📞 Reach us at:\n- Phone: +91 7258803502, +91 9430796895\n- Email: support@encoderssoftware.com';

    case '3':
      return '📍 Our office is located at Oak Forest Flat No 8 F, Pundag Road, Ranchi, Jharkhand 834002.';

    case '4':
      return '🚀 We are hiring! Check our Careers page https://encoderssoftware.com/Careers for exciting opportunities.';

    default:
      return '🤔 I didn’t quite understand that. Could you rephrase or choose an option from the menu?';
  }
};

  

  // Form Handlers
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  const validate = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = "Name is required.";
    if (!formData.email.trim()) newErrors.email = "Email is required.";
    if (!formData.mobile.trim()) newErrors.mobile = "Mobile number is required.";
    if (!formData.message.trim()) newErrors.message = "Message is required.";
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email))  setResponseMessage("Please enter a valid email address.");
    if (!formData.captchaInput.trim()) newErrors.captchaInput = "Captcha is required.";
    if (formData.captchaInput.trim().toLowerCase() !== captcha.trim().toLowerCase()) 
      newErrors.captchaInput = "Captcha does not match.";
    
    return newErrors;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setIsSubmitting(true);
  
    try {
      // Make the API call
      const response = await axios.post(
        config.BASE_URL+"/api/contact/sendMessage",
        formData
      );
      setResponseMessage(response.data); // Display success message
      setFormData({
        name: "",
        email: "",
        mobile: "",
        company: "",
        message: "",
        captchaInput: "",
      });
      fetchCaptcha();
    } catch (error) {
      console.error("Error sending message:", error);
      setResponseMessage("Failed to send the message. Please try again later.");
    }
    finally {
      setIsSubmitting(false);
    }
  };
  // Chatbox Handlers
  

  

  return (
    <div>
       <Navbar></Navbar>
  
    <div className="contact-us">
      {/* Banner Section */}
      <div className="banner">
        <img
          src="/images/big-images/banner_contact.jpg"
          alt="Contact Banner"
          className="banner-image"
        />
        <div className="banner-overlay">
          <h1>Contact Us</h1>
          <p>We’re here to help. Reach out with any queries or feedback.</p>
        </div>
      </div>

      {/* Main Section */}
      <div className="contact-container">
        {/* Company Details */}
        <div className="contact-details">
          <h2>Company Details</h2>
          <p>
            <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" /> Oak Forest Flat No 8 F, Pundag Road, Ranchi, Jharkhand 834002
          </p>
          <p>
            <FontAwesomeIcon icon={faPhone} className="icon" />{" "}
            <a href="tel:+917258803502" className="clickable">
              +91 7258803502
            </a>
          </p>
          <p>
            <FontAwesomeIcon icon={faEnvelope} className="icon" />{" "}
            <a
              href="mailto:support@encoderssoftware.com"
              className="clickable"
            >
              support@encoderssoftware.com
            </a>
          </p>
          <div className="social-links">
            <a href="#" className="social-icon">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a href="#" className="social-icon">
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a href="#" className="social-icon">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
            <a href="https://www.instagram.com/encoders_software.pvt?igsh=MTQ2cTJwaXo1Ymo4dw==" className="social-icon">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a
              href="https://wa.me/917258803502"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              <FontAwesomeIcon icon={faWhatsapp} />
            </a>
          </div>
          <div className="map-container">
          <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3681.137319903208!2d85.3051763153841!3d23.35322608474431!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f39ecdd1e0768b%3A0xd16a2fa4f6c43328!2sFlat%20No%208%20F%2C%20Pundag%20Rd%2C%20Ranchi%2C%20Jharkhand%20834002!5e0!3m2!1sen!2sin!4v1663340766586!5m2!1sen!2sin"
              width="100%"
              height="200"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>

        {/* Contact Form */}
        <div className="contact-form">
          <h2>Send Us a Message</h2>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Your Name *"
              value={formData.name}
              onChange={handleChange}
              required
            />
            {errors.name && <p className="error-text">{errors.name}</p>}
            <input
              type="email"
              name="email"
              placeholder="Your Email Address *"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {errors.email && <p className="error-text">{errors.email}</p>}
            
            <input
              type="tel"
              name="mobile"
              placeholder="Phone Number *"
              value={formData.mobile}
              onChange={handleChange}
              required
            />
            {errors.mobile && <p className="error-text">{errors.mobile}</p>}
            <textarea
              name="message"
              placeholder="Your Message *"
              rows="5"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
         {errors.message && <p className="error-text">{errors.message}</p>}

            <div className="captcha-box">
            <span className="captcha-text">{captcha}</span>
            <button type="button" onClick={fetchCaptcha}>↻</button>
          </div>
          <input
            type="text"
            name="captchaInput"
            placeholder="Enter Captcha *"
            value={formData.captchaInput}
            onChange={handleChange}
          />
          {errors.captchaInput && <p className="error-text">{errors.captchaInput}</p>}


          <button class="attractive-btn" type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Submitting..." : "Send Message"}
          </button>

           
          </form>
          {responseMessage && <p className="response-message">{responseMessage}</p>}
        </div>

   {/* Chatbox Section */}
   <div className="chatbot-container">
      {/* Chat Header */}
      <h2 className="chatbot-header">Chat with Us 🤖</h2>

      {/* Chat Window */}
      <div className="chatbot-window">
        {chatMessages.map((msg, index) => (
          <div key={index} className={`chatbot-message ${msg.sender}`}>
            <span className="chatbot-serial">{index + 1}.</span>
            <div className="chatbot-avatar">
              <FontAwesomeIcon icon={msg.sender === 'bot' ? faRobot : faUser} />
            </div>
            <div className="chatbot-text">{msg.text}</div>
          </div>
        ))}
        {isTyping && (
          <div className="chatbot-typing">
            <FontAwesomeIcon icon={faRobot} /> Bot is typing...
          </div>
        )}
      </div>

      {/* Quick Replies */}
      <div className="chatbot-quick-replies">
        {quickReplies.map((reply, index) => (
          <button key={index} onClick={() => handleQuickReply(reply)} className="quick-reply">
            {reply}
          </button>
        ))}
      </div>

      {/* Chat Input */}
      <div className="chatbot-input-container">
        <input
          type="text"
          placeholder="Type your message..."
          value={chatInput}
          onChange={(e) => setChatInput(e.target.value)}
          onKeyDown={handleChatKeyPress}
          className="chatbot-input"
        />
        <button onClick={handleChatSend} className="chatbot-send-btn">
          <FontAwesomeIcon icon={faPaperPlane} />
        </button>
      </div>
      <p>{responseMessage}</p>
    </div>
      <Footer />
    </div>
    </div>
    </div>
  )
};

export default ContactUs;
