import React from "react";
import "./Home.css";
import Footer from "../../include/Footer";
import Navbar from "../../include/Navbar";
import Slider from '../../include/Slider';
import  { useState, useEffect,useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../include/config";

const AboutCard = ({ title, content, moreContent, showMore, toggleShowMore }) => (
  <div className="about-card rotate-in">
    <h3>{title}</h3>
    <p>{content}</p>
    {showMore && <div className="read-more-content">{moreContent}</div>}
    <button
      onClick={toggleShowMore}
      className="read-more-btn"
      style={{
        backgroundColor: showMore ? '#4CAF50' : '#0056b3',
        color: 'white',
        border: 'none',
        padding: '10px 20px',
        borderRadius: '5px',
        cursor: 'pointer',
        marginTop: '10px',
        fontWeight: 'bold',
        transition: 'background-color 0.3s'
      }}
    >
      {showMore ? 'Read Less' : 'Read More'}
    </button>
  </div>
  );


const Home = () => {


  const [showMoreMission, setShowMoreMission] = useState(false);
  const [showMoreVision, setShowMoreVision] = useState(false);
  const [showMoreWhoWeAre, setShowMoreWhoWeAre] = useState(false);
  const [showMoreWhatWeDo, setShowMoreWhatWeDo] = useState(false);
  const [showMoreCoreValues, setShowMoreCoreValues] = useState(false);
  const [showMoreISO, setShowMoreISO] = useState(false);




const [activeCard, setActiveCard] = useState(null);
const [news, setNews] = useState([]);
const [services, setServices] = useState([]);
const [projects, setProjects] = useState([]);
const [testimonials, setTestimonials] = useState([]);
const [faqs, setFaqs] = useState([]);
const [error, setError] = useState(null);

const [page, setPage] = useState(0); // Page number for pagination
const [hasMore, setHasMore] = useState(true); // Whether more news exists
const [loading, setLoading] = useState(false); // Loading state
const [autoScrollComplete, setAutoScrollComplete] = useState(false); // Auto-scroll flag

  const scrollIntervalRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false); // Track hover state


const newsContainerRef = useRef(null);

const navigate = useNavigate();




/** Fetch Initial News **/
useEffect(() => {
  const fetchInitialNews = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(`${config.BASE_URL}/api/latest-news/getAll`, {
        params: {
          view: "pagewise",
          page: 0,
          size: 20,
        },
      });
      const data = response.data;

      setNews(data.content || []);
      setPage(1); // Prepare for the next batch
      setHasMore(data.content?.length > 0);
    } catch (err) {
      console.error("Error fetching initial news:", err);
      setError("Failed to load news. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  fetchInitialNews();
}, []);

/** Fetch More News **/
const fetchMoreNews = async () => {
  if (loading || !hasMore) return;

  setLoading(true);
  setError(null);

  try {
    const response = await axios.get(`${config.BASE_URL}/api/latest-news/getAll`, {
      params: {
        view: "pagewise",
        page: page,
        size: 20,
      },
    });
    const data = response.data;

    if (data.content && data.content.length > 0) {
      setNews((prevNews) => [...prevNews, ...data.content]);
      setPage((prevPage) => prevPage + 1);
    } else {
      setHasMore(false); // No more news to load
    }
  } catch (err) {
    console.error("Error fetching more news:", err);
    setError("Failed to load more news. Please try again later.");
  } finally {
    setLoading(false);
  }
};

/** Auto-Scroll Logic **/
useEffect(() => {
  const container = newsContainerRef.current;

  if (!container || autoScrollComplete) return;

  const startAutoScroll = () => {
    if (!isHovered && !autoScrollComplete) {
      scrollIntervalRef.current = setInterval(() => {
        if (container.scrollTop + container.clientHeight < container.scrollHeight) {
          container.scrollTop += 1; // Smooth slow scroll
        } else {
          clearInterval(scrollIntervalRef.current);
          setAutoScrollComplete(true); // Mark scroll as complete
        }
      }, 50); // Adjust scroll speed
    }
  };

  startAutoScroll();

  return () => clearInterval(scrollIntervalRef.current);
}, [news, isHovered, autoScrollComplete]);

/** Pause and Resume Scroll on Hover **/
const handleMouseEnter = () => {
  setIsHovered(true);
  if (scrollIntervalRef.current) {
    clearInterval(scrollIntervalRef.current); // Stop scrolling immediately on hover
  }
};

const handleMouseLeave = () => {
  setIsHovered(false);
  if (!autoScrollComplete) {
    const container = newsContainerRef.current;
    scrollIntervalRef.current = setInterval(() => {
      if (container.scrollTop + container.clientHeight < container.scrollHeight) {
        container.scrollTop += 1;
      } else {
        clearInterval(scrollIntervalRef.current);
        setAutoScrollComplete(true);
      }
    }, 50);
  }
};



const handleNavigate = () => {
    navigate("/contact"); 
    setTimeout(() => {
      let attempts = 0;
      const scrollInterval = setInterval(() => {
        const contactForm = document.querySelector(".contact-form");
        console.log(`Checking for .contact-form... Attempt: ${attempts}`);
  
        if (contactForm) {
          contactForm.scrollIntoView({ behavior: "smooth", block: "start" });
          clearInterval(scrollInterval); // Stop checking once found
        } else if (attempts > 10) {
          console.error("contact-form not found after multiple attempts!");
          clearInterval(scrollInterval);
        }
        attempts++;
      }, 300); // Check every 300ms
    }, 1000); // Small delay to allow navigation
    
    
  };

const handleCardClick = (e) => {
  const card = e.currentTarget;
  if (card.classList.contains("zoom")) {
    card.classList.remove("zoom");
  } else {
    card.classList.add("zoom");
  }
};

  const sliderRef = useRef(null);




  const handleScrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft -= 300; // Adjust scroll distance as needed
    }
  };

  const handleScrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft += 300; // Adjust scroll distance as needed
    }
  };




  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get(`${config.BASE_URL}/api/services/getAllService`);
        setServices(response.data); // Update state with the fetched data
      } catch (err) {
        console.error("Error fetching news:", err);
        setError("Failed to fetch the latest news. Please try again later.");
      }
    };

    fetchServices();
  }, []);

  

 

 



  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(`${config.BASE_URL}/api/projects/getAllProjects`);
        setProjects(response.data); // Update state with the fetched data
      } catch (err) {
        console.error("Error fetching news:", err);
        setError("Failed to fetch the latest news. Please try again later.");
      }
    };

    fetchProjects();
  }, []);




  useEffect(() => {
    const fetchtestimonials = async () => {
      try {
        const response = await axios.get(`${config.BASE_URL}/api/testimonials`);
        setTestimonials(response.data); // Update state with the fetched data
      } catch (err) {
        console.error("Error fetching news:", err);
        setError("Failed to fetch the latest news. Please try again later.");
      }
    };

    fetchtestimonials();
  }, []);




  useEffect(() => {
    const fetchfaqs = async () => {
      try {
        const response = await axios.get(`${config.BASE_URL}/api/faqs/getAllFaqs`);
        setFaqs(response.data); // Update state with the fetched data
      } catch (err) {
        console.error("Error fetching news:", err);
        setError("Failed to fetch the latest news. Please try again later.");
      }
    };

    fetchfaqs();
  }, []);

  

  
  const [expandedFaq, setExpandedFaq] = useState(null);

  const toggleFaq = (id) => {
    setExpandedFaq((prevId) => (prevId === id ? null : id));
  };


  return (
    <div>
  <Navbar></Navbar>
 <Slider></Slider>
    <div className="homepage">
      {/* Banner Section */}
    

      {/* About Us Section */}
     {/* About Us Section */}

     <section className="about-us">
  <h1>Welcome to Encoders Software</h1>
  <p>Empowering Businesses with Innovative IT Solutions for a Digital-First Future</p>
{/* About Us Section */}



<section className="iso-certification">
  <h2>ISO 9001:2015 Certification</h2>
  <p>
    Encoders Software is proud to be <strong>ISO 9001:2015 certified</strong>, a globally recognized standard for
    <strong> Quality Management Systems (QMS)</strong>. This certification demonstrates our unwavering commitment to
    excellence, efficiency, and customer satisfaction.
  </p>
  <ul>
    <li><strong>Certificate No : </strong> QMS/230620/3778</li>
  </ul>
  <p>
    This certification reflects our dedication to meeting international quality standards and continuously improving
    our services to exceed customer expectations.
  </p>
</section>



  {/* About Us Section */}
  <h2>About Us</h2>
  <div className="about-us-cards">
    {/* Who We Are */}
    <AboutCard
      title="Who We Are"
      content="Encoders Software Private Limited, founded in 2019, is a leading IT solutions provider headquartered in Jharkhand, India."
      moreContent={
        <>
          <p>Driven by innovation and excellence, we specialize in delivering comprehensive digital solutions that help businesses stay ahead.</p>
          <p>Our team of professionals is dedicated to understanding your business needs and offering tailored solutions that meet your goals.</p>
          <p>We prioritize quality, efficiency, and customer satisfaction in every project we undertake.</p>
          <p>Over the years, we've built a reputation for excellence and have become a trusted technology partner for businesses across various industries.</p>
          <ul>
            <li><strong>Founded:</strong> 2019</li>
            <li><strong>Headquarters:</strong> Ranchi, Jharkhand, India</li>
            <li><strong>Authorized Capital:</strong> ₹10,00,000</li>
            <li><strong>Paid-Up Capital:</strong> ₹1,00,000</li>
            <li><strong>CIN:</strong> U74999JH2019PTC012902</li>
            <li><strong>Registration Number:</strong> 12902</li>
          </ul>
          <p>We believe in fostering strong relationships with our clients, partners, and employees to achieve shared success.</p>
          <p>Our leadership team brings decades of experience in the IT industry, ensuring strategic growth and long-term sustainability.</p>
        </>
      }
      showMore={showMoreWhoWeAre}
      toggleShowMore={() => setShowMoreWhoWeAre(!showMoreWhoWeAre)}
    />

    {/* Our Mission */}
    <AboutCard
      title="Our Mission"
      content="Empowering businesses with innovative and scalable technology solutions."
      moreContent={
        <>
          <p>Our mission is to redefine business efficiency, address emerging needs, and create sustainable growth through bespoke IT solutions.</p>
          <p>We believe in harnessing the power of technology to create opportunities, drive innovation, and transform industries.</p>
          <p>At Encoders Software, our mission is built around customer-centricity, adaptability, and long-term success.</p>
          <ul>
            <li>Digital Empowerment</li>
            <li>Innovation-First Approach</li>
            <li>Customer-Centric Solutions</li>
            <li>Sustainable Growth</li>
            <li>Talent Development</li>
            <li>Community Engagement</li>
          </ul>
          <p>We are dedicated to building sustainable, scalable, and innovative solutions that help our clients stay ahead of the competition.</p>
          <p>Our mission drives us to create technology ecosystems that deliver seamless integration and measurable results.</p>
          <p>By collaborating with stakeholders, we aim to build long-lasting relationships rooted in trust and mutual respect.</p>
        </>
      }
      showMore={showMoreMission}
      toggleShowMore={() => setShowMoreMission(!showMoreMission)}
    />

<AboutCard
      title="What We Do"
      content="At Encoders Software, we provide end-to-end technology solutions to drive business growth."
      moreContent={
        <>
          <p>We offer a wide range of technology services tailored to meet the unique needs of our clients.</p>
          <ul>
            <li><strong>Web Development:</strong> Scalable, secure, and user-friendly websites tailored to your business goals.</li>
            <li><strong>Mobile App Development:</strong> Intuitive iOS and Android applications delivering seamless experiences.</li>
            <li><strong>SaaS Solutions:</strong> Powerful SaaS products designed to address specific industry challenges.</li>
            <li><strong>Government IT Support:</strong> Specialized IT services for government projects and digital governance.</li>
            <li><strong>Cloud Integration:</strong> Secure and seamless cloud adoption and migration strategies.</li>
            <li><strong>Cybersecurity Solutions:</strong> Advanced measures to protect digital assets and sensitive information.</li>
            <li><strong>AI & ML Integrations:</strong> Innovative AI-driven insights and machine learning automation.</li>
            <li><strong>Corporate Training:</strong> Customized training programs for teams to stay ahead in the digital age.</li>
            <li><strong>API Development:</strong> Robust and secure APIs for seamless integrations.</li>
            <li><strong>IT Consulting:</strong> Expert advice for digital transformation and IT infrastructure optimization.</li>
          </ul>
          <p>We follow agile methodologies, ensuring flexibility, adaptability, and transparency in all our projects.</p>
          <p>From startups to large enterprises, we design technology solutions that drive growth and efficiency.</p>
          <p>Our commitment to quality and customer satisfaction ensures long-term success for our clients.</p>
        </>
      }
      showMore={showMoreWhatWeDo}
      toggleShowMore={() => setShowMoreWhatWeDo(!showMoreWhatWeDo)}
    />

    {/* Our Vision */}
    <AboutCard
      title="Our Vision"
      content="To become a global leader in transformative technology solutions."
      moreContent={
        <>
          <p>At Encoders Software, our vision is to lead the world in innovative and sustainable technology solutions.</p>
          <p>We envision a future where technology empowers organizations, fosters growth, and transforms industries worldwide.</p>
          <ul>
            <li>Global Leadership in IT Innovation</li>
            <li>Continuous Research and Development</li>
            <li>Focus on Client-Centric Outcomes</li>
            <li>Scalable and Future-Ready Infrastructure</li>
            <li>Fostering Collaborative Partnerships</li>
            <li>Commitment to Environmental Sustainability</li>
          </ul>
          <p>We aim to be recognized not just as a service provider, but as a trusted partner in technological excellence and innovation.</p>
        </>
      }
      showMore={showMoreVision}
      toggleShowMore={() => setShowMoreVision(!showMoreVision)}
    />

    {/* Our Core Values */}
    <AboutCard
      title="Our Core Values"
      content="Integrity, Innovation, Excellence, and Collaboration."
      moreContent={
        <>
          <ul>
            <li>Integrity: Honesty and transparency in all our actions.</li>
            <li>Innovation: Continuously pushing boundaries to deliver creative solutions.</li>
            <li>Excellence: Committed to the highest quality standards in every project.</li>
            <li>Collaboration: Working together to achieve shared goals.</li>
            <li>Sustainability: Building solutions with long-term environmental and economic benefits.</li>
            <li>Accountability: Taking responsibility for our actions and commitments.</li>
            <li>Customer Focus: Prioritizing client satisfaction in every decision.</li>
          </ul>
        </>
      }
      showMore={showMoreCoreValues}
      toggleShowMore={() => setShowMoreCoreValues(!showMoreCoreValues)}
    />
  </div>

  {/* Call to Action */}
  <div className="about-cta">
    <h3>Let's Build Something Extraordinary Together</h3>
    <p>Whether you're a startup, an enterprise, or a government agency, we have the expertise to drive your digital transformation.</p>
    <a href="/contact" className="cta-button">Get in Touch</a>
  </div>
</section>


  

<div className="banner">
  <img src="/images/big-images/1.jpg" alt="Banner Background" className="banner-image" />
  <div className="banner-overlay">
    <div className="banner-content">
    <h1>Innovate. Transform. Succeed.</h1>
      <p>
        At Encoders Software, we empower businesses to achieve their full potential 
        through cutting-edge technology and tailored IT solutions. Let us be your 
        partner in digital transformation.
      </p>
    
    </div>
  </div>
</div>




     {/* Our Services Section */}
<section className="services">
  <h2>Our Services</h2>
  <div className="services-grid">
    {services.map((service, index) => (
      <div
        key={index}
        className="service-card"
        onClick={(e) => handleCardClick(e)}
      >
        
        <img src={`data:image/jpeg;base64,${service.image}`} alt={service.title} className="service-image" />
        <div className="service-content">
          <h3 className="service-title">{service.title}</h3>
          <p className="service-description">{service.description}</p>
        </div>
      </div>
    ))}
  </div>
</section>

    {/* Recent Projects and Testimonials Section */}
    <section className="work-and-testimonials">
      <h2>Our Work and Testimonials</h2>

      {/* Recent Projects Row */}
      <div className="recent-projects">
        <h3>Our Recent Projects</h3>
        <div className="projects-container">
          <button className="scroll-button left" onClick={handleScrollLeft}>
            &#8592; {/* Left arrow */}
          </button>
          <div className="projects-slider" ref={sliderRef}>
            {projects.map((project) => (
              <div key={project.id} className="project-item">
                <img src={`data:image/jpeg;base64,${project.image}`} alt={project.title} />
                <h4>{project.title}</h4>
                <p>{project.description}</p>
              </div>
            ))}
          </div>
          <button className="scroll-button right" onClick={handleScrollRight}>
            &#8594; {/* Right arrow */}
          </button>
        </div>
      </div>

      {/* Testimonials Row */}
      <div className="testimonials">
      <h3>What Our Clients Say</h3>
      <div className="testimonials-container">
        {testimonials.map((testimonial) => (
          <div key={testimonial.id} className="testimonial-item">
            "{testimonial.message}" - {testimonial.client}
          </div>
        ))}
      </div>
    </div>
    </section>


<div className="banner">
  <img src="/images/big-images/11.jpg" alt="Banner Background" className="banner-image" />
  <div className="banner-overlay">
    <div className="banner-content">
      <h1>Transforming Ideas into Reality</h1>
      <p>
        Partner with Encoders Software to bring your innovative concepts to life. 
        Let’s collaborate to create exceptional IT solutions tailored for your business.
      </p>
     
      
    </div>
  </div>
</div>


{/* News and FAQ Section */}
<section className="stay-updated">
      {/* Latest News Section */}
      <section className="latest-news">
      <h2>Latest News</h2>
      {error && <p className="error-message">{error}</p>}

      <div
        className="news-container"
        ref={newsContainerRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          maxHeight: "400px",
          overflowY: "auto",
          scrollBehavior: "smooth",
          border: "1px solid #ddd",
          padding: "10px",
        }}
      >
        {news.map((newsItem) => (
          <div key={newsItem.id} className="news-item">
            <h4>{newsItem.title}</h4>
            <p>{newsItem.description}</p>
            <small>{new Date(newsItem.createdDate).toLocaleString()}</small>
          </div>
        ))}
      </div>

      {/* Loading State */}
      {loading && <p>Loading...</p>}

      {/* Load More Button */}
      {hasMore && (
        <button onClick={fetchMoreNews} className="load-more-button">
          Load More
        </button>
      )}
      {!hasMore && <p>No more news available.</p>}
    </section>

      {/* FAQs Section */}
      <div className="faqs">
        <h3>Quick FAQs</h3>
        {faqs.map((faq) => (
          <div
            key={faq.id}
            className={`faq-item ${expandedFaq === faq.id ? "expanded" : ""}`}
            onClick={() => toggleFaq(faq.id)}
          >
            <h4>{faq.question}</h4>
            {expandedFaq === faq.id && <p>{faq.answer}</p>}
          </div>
        ))}
      </div>
    </section>




      {/* Contact CTA Section */}
      <section className="contact-cta">
      <h2>Let’s Collaborate</h2>
      <p>Contact us today to discuss how we can help transform your ideas into reality.</p>
      <button onClick={handleNavigate} className="cta-button">
        Get in Touch
      </button>
    </section>
    </div>
    <Footer></Footer>
    </div>
  );
};

export default Home;
