import React, { useState, useEffect,useContext } from "react";
import axiosInstance from "./axiosInstance";
import { FaCheck, FaTimes, FaDownload, FaEye, FaMoneyCheckAlt } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ApplicationManagement.css";
import PaymentDetailsForm from "./PaymentDetailsForm";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { SharedStateContext } from "../../SharedStateContext";
import { useDebounce } from "use-debounce";
import InterViewStatusForm from "./InterViewStatusForm";




const ApplicationManagement = () => {
  const [applications, setApplications] = useState([]);
  const [resumeView, setResumeView] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(2);
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);
  const [declineReasonVisible, setDeclineReasonVisible] = useState(false);
  const [selectedApplicationId, setSelectedApplicationId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [loadingActionId, setLoadingActionId] = useState(null);
  const [actionType, setActionType] = useState("");
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [showInterViewStatusForm, setShowInterViewStatusForm] = useState(false);
  

const [debouncedSearchQuery] = useDebounce(searchQuery, 500);

  const { refreshFlag } = useContext(SharedStateContext);

  useEffect(() => {
    fetchApplications();
  }, [debouncedSearchQuery,searchQuery, statusFilter, currentPage, pageSize, refreshFlag]);



 
  const fetchApplications = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get("/api", {
        params: {
          page: currentPage - 1,
          size: pageSize,
          search: searchQuery.trim(),
          status: statusFilter.trim(),
        },
      });
      const { content, totalPages } = response.data;
      setApplications(content || []);
      setTotalPages(totalPages || 1);
    } catch (error) {
      console.error("Error fetching applications:", error.response?.data || error.message);
      toast.error("Failed to load applications.");
    } finally {
      setLoading(false);
    }
  };



  const handleGenerateRelievingLetter = async (applicationId) => {
    setLoadingActionId(applicationId);
    setActionType("generateRelieving");
    try {
      await axiosInstance.post(`/api/applications/exit-employment/${applicationId}`);
      toast.success("Relieving letter generated successfully!");
      fetchApplications(); // Refresh the application data
    } catch (error) {
      console.error("Error generating relieving letter:", error.response?.data || error.message);
      toast.error("Failed to generate relieving letter.");
    } finally {
      setLoadingActionId(null);
      setActionType("");
    }
  };

  const handleDownloadRelievingLetter = async (application) => {
    try {
      const response = await axiosInstance.get("/api/applications/download-relieving-letter", {
        params: {
          empNo: application.empNo,
          email: application.email,
        },
        responseType: "arraybuffer", // Treat response as binary data
      });
  
      // Create a Blob from the binary data
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
  
      // Create a link to trigger the file download
      const link = document.createElement("a");
      link.href = URL.createObjectURL(pdfBlob);
      link.download = "Relieving_cum_experiance_Letter.pdf";
      link.click();
      toast.success("Relieving letter downloaded successfully!");
    } catch (error) {
      console.error("Error downloading relieving letter:", error.response?.data || error.message);
      toast.error("Failed to download relieving letter.");
    }
  };


  const handleRevokeOfferLetter = async (applicationId) => {
    setLoadingActionId(applicationId);
    setActionType("revoke");
    try {
      await axiosInstance.post(`/api/applications/revoke-offer/${applicationId}`);
      toast.success("Offer letter revoked successfully!");
      fetchApplications();
    } catch (error) {
      console.error("Error revoking offer letter:", error.response?.data || error.message);
      toast.error("Failed to revoke offer letter.");
    } finally {
      setLoadingActionId(null);
      setActionType("");
    }
  };


 
  
    const handleDownloadOfferLetter = async (application) => {
      try {
        // Make the GET request to download the offer letter
        const response = await axiosInstance.get("/api/applications/download-offer-letter", {
          params: {
            empNo: application.empNo,
            email: application.email,
          },
          responseType: 'arraybuffer', // Important: This ensures the response is treated as binary data
        });
    
        // Create a Blob from the binary data
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
    
        // Create a link to trigger the file download
        const link = document.createElement('a');
        link.href = URL.createObjectURL(pdfBlob);
        link.download = 'offer-letter.pdf'; // You can change the name of the file here
        link.click(); // Trigger the download
    
        console.log('Offer letter downloaded successfully!');
      } catch (error) {
        console.error('Error downloading offer letter:', error);
      }
    };
  
     
  

  const handleAccept = async (id) => {
    setLoadingActionId(id);
    setActionType("accept");
    try {
      await axiosInstance.put(`/api/${id}/accept`);
      toast.success("Application accepted successfully!");
      fetchApplications();
    } catch (error) {
      console.error("Error accepting application:", error.response?.data || error.message);
      toast.error("Failed to accept application.");
    } finally {
      setLoadingActionId(null);
      setActionType("");
    }
  };

  const handleRejectClick = (id) => {
    setSelectedApplicationId(id);
    setDeclineReasonVisible(true);
  };

  const handleDeclineSubmit = async () => {
    if (!reason.trim()) {
      toast.error("Reason is required to decline the application.");
      return;
    }
    setLoadingActionId(selectedApplicationId);
    setActionType("reject");
    try {
      await axiosInstance.put(`/api/${selectedApplicationId}/decline`, { reason });
      toast.success("Application declined successfully!");
      setDeclineReasonVisible(false);
      setReason("");
      fetchApplications();
    } catch (error) {
      console.error("Error declining application:", error.response?.data || error.message);
      toast.error("Failed to decline application.");
    } finally {
      setLoadingActionId(null);
      setActionType("");
    }
  };

  const handleViewResume = async (id) => {
    try {
      const response = await axiosInstance.get(`/api/${id}/resume/view`, {
        responseType: "blob",
      });
      const fileURL = URL.createObjectURL(response.data);
      setResumeView(fileURL);
    } catch (error) {
      console.error("Error loading resume:", error.response?.data || error.message);
      toast.error("Failed to load resume.");
    }
  };

  const handleDownloadResume = async (id) => {
    try {
      const response = await axiosInstance.get(`/api/${id}/resume/download`, {
        responseType: "blob",
      });
      const contentDisposition = response.headers["content-disposition"];
      const fileName = contentDisposition
        ? contentDisposition.split("filename=")[1].replace(/"/g, "")
        : `Resume_${id}.pdf`;

      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      toast.success("Resume downloaded successfully!");
    } catch (error) {
      console.error("Error downloading resume:", error.response?.data || error.message);
      toast.error("Failed to download resume.");
    }
  };

  const handleOpenPaymentForm = (application) => {
    setSelectedApplication(application);
    setShowPaymentForm(true);
  };

  const handleOpenInterViewStatusForm = (application) => {
    setSelectedApplication(application);
    setShowInterViewStatusForm(true);
  };


  const handlePaymentSubmit = (details) => {
    setPaymentDetails(details);
    setShowPaymentForm(false);
  };

  return (
    <div className="application-management-container">
      <ToastContainer />
      <h1>Manage Applications</h1>
      <div className="actions">
        <input
          type="text"
          placeholder="Search applications..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button className="search-button" onClick={fetchApplications}>
          Search
        </button>
        <select
          value={pageSize}
          onChange={(e) => setPageSize(parseInt(e.target.value, 10))}
        >
          <option value="2">2 per page</option>
          <option value="3">3 per page</option>
          <option value="5">5 per page</option>
        </select>
        <select
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
        >
          <option value="">All Statuses</option>
          <option value="Accepted">Accepted</option>
          <option value="DECLINED">DECLINED</option>
          <option value="Pending">Pending</option>
        </select>
      </div>

      {loading && <div className="loading-spinner small"></div>}
      {resumeView && (
        <div className="resume-viewer">
          <iframe src={resumeView} width="100%" height="500px" title="Resume Viewer"></iframe>
          <button className="close-button" onClick={() => setResumeView(null)}>
            Close Viewer
          </button>
        </div>
      )}

      {!loading && applications.length > 0 && (
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Mobile</th>
              <th>Job ID</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {applications.map((application) => (
              <tr key={application.id}>
                <td>{application.firstName + " " + application.lastName}</td>
                <td>{application.mobile}</td>
                <td>{application.jobId}</td>
                <td>{application.status}</td>
                <td>
                {application.status === "PENDING" && (
  <td>
    <button
      className="action-button accept"
      onClick={() => handleAccept(application.id)}
      disabled={loadingActionId === application.id && actionType === "accept"}
    >
      <FaCheck /> Accept
    </button>
    <button
      className="action-button decline"
      onClick={() => handleRejectClick(application.id)}
      disabled={loadingActionId === application.id && actionType === "reject"}
    >
      <FaTimes /> Reject
    </button>

    <button
          className="action-button view"
          onClick={() => handleViewResume(application.id)}
        >
          <FaEye /> View Resume
        </button>
        <button
          className="action-button download"
          onClick={() => handleDownloadResume(application.id)}
        >
          <FaDownload /> Download Resume
        </button>
  </td>
)}

{application.status === "ACCEPTED" && (
  <>
    {application.offerLetterGenerated ? (
  // Revoke Offer Letter and Download Offer Letter Buttons
  <>
    <button
      className="action-button revoke"
      onClick={() => handleRevokeOfferLetter(application.id)}
      disabled={loadingActionId === application.id && actionType === "revoke"}
    >
      <FaTimes /> Revoke Offer Letter
    </button>
    <button
      className="action-button download"
      onClick={() => handleDownloadOfferLetter(application)}
    >
      <FaDownload /> Download Offer Letter
    </button>

    {/* Relieving Letter Actions */}
    {application.exitEmployment ? (
      <button
        className="action-button download"
        onClick={() => handleDownloadRelievingLetter(application)}
      >
        <FaDownload /> Download Relieving Letter
      </button>
    ) : (
      <button
        className="action-button generate"
        onClick={() => handleGenerateRelievingLetter(application.id)}
      >
        <FaCheck /> Generate Relieving Letter
      </button>
    )}
  </>
): (
      <>
        {/* Show Interview Status Update Button */}
        {(application.selectionStatus === 0) && (
  <button
    className="action-button payment"
    onClick={() => handleOpenInterViewStatusForm(application)}
  >
    <FaMoneyCheckAlt /> Interview Status Update
  </button>
)}

        {/* Show Generate Offer Letter Button Only When SelectionStatus is True */}
        {(application.selectionStatus===1) && (
          <button
            className="action-button payment"
            onClick={() => handleOpenPaymentForm(application)}
          >
            <FaMoneyCheckAlt /> Generate Offer Letter
          </button>
        )}
      </>
    )}

    {/* Resume Actions: Visible only if Offer Letter is NOT Generated */}
    {!application.offerLetterGenerated && (
      <>
        <button
          className="action-button view"
          onClick={() => handleViewResume(application.id)}
        >
          <FaEye /> View Resume
        </button>
        <button
          className="action-button download"
          onClick={() => handleDownloadResume(application.id)}
        >
          <FaDownload /> Download Resume
        </button>
      </>
    )}
  </>
)}
               
               
               
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {declineReasonVisible && (
        <div className="decline-reason-container active">
          <textarea
            placeholder="Enter reason for rejection..."
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          ></textarea>
          <div className="button-group">
            <button
              className="action-button cancel"
              onClick={() => {
                setReason("");
                setDeclineReasonVisible(false);
              }}
            >
              Cancel
            </button>
            <button className="action-button decline" onClick={handleDeclineSubmit}>
              Confirm Decline
            </button>
          </div>
        </div>
      )}

      {showPaymentForm && (
        <PaymentDetailsForm
          application={selectedApplication}
          onSubmit={handlePaymentSubmit}
          onCancel={() => setShowPaymentForm(false)}
        />
      )}

{showInterViewStatusForm && (
  <InterViewStatusForm
    application={selectedApplication}
    onSubmit={(data) => {
     /// toast.success("Interview status updated successfully!");
      setShowInterViewStatusForm(false); // Close the form
      fetchApplications(); // Refresh applications
    }}
    onCancel={() => setShowInterViewStatusForm(false)} // Close form on cancel
  />
)}



      <div className="pagination">
        <button
          disabled={currentPage === 1}
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ApplicationManagement;
