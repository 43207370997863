import React, { useState, useEffect, useCallback, useRef, lazy, Suspense } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axiosInstance from "./axiosInstance";
import { FaSearch } from "react-icons/fa";
import { ImSpinner2 } from "react-icons/im";
import _ from "lodash";
import { useImageCache } from "./ImageCacheContext"; // Import the context hook
import "./BlogsUsers.css";
import { useLocation } from "react-router-dom";


// Lazy Load Components
const CategoryList = lazy(() => import("./CategoryList"));
const BlogCard = lazy(() => import("./BlogCard"));
const Footer = lazy(() => import("../../include/Footer"));
const Navbar = lazy(() => import("../../include/Navbar"));
const CommentSection = lazy(() => import("./CommentSection"));
const Slider = lazy(() => import("../../include/Slider"));

// Custom Debounce Hook for Search
const useDebounce = (callback, delay) => {
  const debouncedCallback = useRef(_.debounce(callback, delay)).current;
  return useCallback((...args) => debouncedCallback(...args), [debouncedCallback]);
};

const BlogsUsers = () => {
  const { blogId } = useParams();
  const navigate = useNavigate();
  const { addImageToCache, getImageFromCache } = useImageCache(); // Use the context hook

  const [categories, setCategories] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [fetching, setFetching] = useState(false);
  const [searching, setSearching] = useState(false);
  const location = useLocation();


useEffect(() => {
    setTimeout(() => {
      const contactForm = document.querySelector(".blogs-container");
      console.log("Attempting to scroll to .blogs-container...");

      if (contactForm) {
        contactForm.scrollIntoView({ behavior: "smooth", block: "start" });
      } else {
        console.error("blogs-container not found!");
      }
    }, 500); // Small delay to ensure rendering completes
  }, [location]);



  // Fetch Categories
  useEffect(() => {
    const fetchCategories = async () => {
      setFetching(true);
      try {
        const response = await axiosInstance.get("/api/user/blogs/categories");
        setCategories(response.data.content || []);
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
        setFetching(false);
      }
    };
    fetchCategories();
  }, []);

  // Fetch Blogs
  useEffect(() => {
    const fetchBlogs = async () => {
      setFetching(true);
      try {
        const response = await axiosInstance.get("/api/user/blogs", {
          params: { page: currentPage, size: 6, search: searchQuery, categoryId: selectedCategory },
        });
        setBlogs((prevBlogs) =>
          currentPage === 0 ? response.data.content : [...prevBlogs, ...response.data.content]
        );
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchBlogs();
  }, [currentPage, searchQuery, selectedCategory]);

  // Fetch blog by ID if opening directly via URL
  useEffect(() => {
    if (blogId) {
      fetchBlogById(blogId);
    }
  }, [blogId]);

  const fetchBlogById = async (id) => {
    setFetching(true);
    try {
      const response = await axiosInstance.get(`/api/user/blogs/${id}`);
      setSelectedBlog(response.data);
    } catch (error) {
      console.error("Error fetching blog:", error);
    }
    setFetching(false);
  };

  const handleCategoryClick = useCallback((categoryId) => {
    setSelectedCategory(categoryId);
    resetBlogs();
  }, []);

  // Handle Debounced Search
  const debounceSearch = useDebounce((query) => {
    setSearchQuery(query);
    resetBlogs();
    setSearching(false);
  }, 300);

  const handleSearch = (e) => {
    setSearching(true);
    debounceSearch(e.target.value);
  };

  const resetBlogs = () => {
    setCurrentPage(0);
    setBlogs([]);
    setSelectedBlog(null);
  };

  const handleBlogClick = (blog) => {
    navigate(`/blogs/${blog.id}`);
    setSelectedBlog(blog);
  };

  const handleBackToList = () => {
    navigate("/blogs"); // Navigates back to blog list
    setSelectedBlog(null);
  };

  const handleLoadMore = () => {
    if (currentPage + 1 < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  // Load Image from Cache or API
  const loadImage = (blogId, imageUrl) => {
    const cachedImage = getImageFromCache(blogId); // Check cache first
    if (cachedImage) {
      return cachedImage; // Return cached image
    }

    // If image not in cache, fetch and store it
    const base64Image = `data:image/png;base64,${imageUrl}`;
    addImageToCache(blogId, base64Image); // Cache the image
    return base64Image;
  };

  return (
    <div>
      <Suspense fallback={<div className="loader-container"><ImSpinner2 className="spinner-icon" /></div>}>
        <Navbar />
        <Slider />
      </Suspense>

      <div className="blogs-container">
        <h1>📝 Encoders Software Blogs</h1>
        <div className="layout">
          <Suspense fallback={<div className="loader-container"><ImSpinner2 className="spinner-icon" /></div>}>
            <CategoryList categories={categories} onCategoryClick={handleCategoryClick} selectedCategory={selectedCategory} />
          </Suspense>

          <div className="main-content">
            {fetching ? (
              <div className="blog-loader">
                <div className="simple-loader">Loading blogs...</div>
              </div>
            ) : selectedBlog ? (
              <div className="blog-detail">
                <button onClick={handleBackToList} className="back-btn">
                  ← Back to Blogs
                </button>
                <Suspense fallback={<div className="loader-container"><ImSpinner2 className="spinner-icon" /></div>}>
                  <BlogCard blog={selectedBlog} detailed />
                  <CommentSection blogId={selectedBlog.id} />
                </Suspense>
              </div>
            ) : (
              <>
                <div className="search-bar">
                  <input type="text" placeholder="Search blogs..." onChange={handleSearch} aria-label="Search blogs" />
                  <button>
                    <FaSearch />
                  </button>
                </div>

                {searching ? (
                  <div className="loader-container">
                    <ImSpinner2 className="spinner-icon" />
                    <p>Searching blogs...</p>
                  </div>
                ) : blogs.length > 0 ? (
                  <div className="blogs-grid">
                    {blogs.map((blog) => (
                      <Suspense key={blog.id} fallback={<div className="blog-placeholder"><ImSpinner2 className="large-spinner" /></div>}>
                        <BlogCard
                          blog={blog}
                          onClick={() => handleBlogClick(blog)}
                          imageSrc={loadImage(blog.id, blog.image)} // Use cached or loaded image
                        />
                      </Suspense>
                    ))}
                  </div>
                ) : (
                  <p className="no-blogs">No blogs found.</p>
                )}

                {currentPage + 1 < totalPages && blogs.length > 0 && (
                  <button className="load-more-btn" onClick={handleLoadMore}>
                    Load More
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <Suspense fallback={<div className="loader-container"><ImSpinner2 className="spinner-icon" /></div>}>
        <Footer />
      </Suspense>
    </div>
  );
};

export default BlogsUsers;