import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "./axiosInstance";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./MerchantPay.css";

export default function MerchantPay() {
  const location = useLocation();
  const navigate = useNavigate();
  const course = location.state?.course || {};

  const userId = course.courseUserId || "";
  const userEmail = localStorage.getItem("email");

  const [discountCode, setDiscountCode] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState(course.originalPrice || 0); // Ensure default value is set
  const [gst, setGst] = useState(0);
  const [cst, setCst] = useState(0);
  const [totalAmount, setTotalAmount] = useState(course.originalPrice || 0); // Ensure default value is set
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState(userEmail);
  const [mobile, setMobile] = useState("");
  const [applyingDiscount, setApplyingDiscount] = useState(false);
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [redirecting, setRedirecting] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState(null);

  useEffect(() => {
    // Disable F5 and Ctrl+R (Refresh)
    const disableRefresh = (event) => {
      if (event.key === "F5" || (event.ctrlKey && event.key === "r")) {
        event.preventDefault();
      }
    };
    const disableInspectElement = (event) => {
      if (
        event.key === "F12" ||
        (event.ctrlKey && event.shiftKey && event.key === "I") ||
        (event.ctrlKey && event.shiftKey && event.key === "J") ||
        (event.ctrlKey && event.key === "U")
      ) {
        event.preventDefault();
      }
    };

    // Disable Right Click Menu
    const disableRightClick = (event) => event.preventDefault();

    // Add Event Listeners
    document.addEventListener("contextmenu", disableRightClick);
    document.addEventListener("keydown", disableInspectElement);
    document.addEventListener("keydown", disableRefresh);

    return () => {
      // Remove Event Listeners
      document.removeEventListener("contextmenu", disableRightClick);
      document.removeEventListener("keydown", disableInspectElement);
      document.removeEventListener("keydown", disableRefresh);
    };
  }, []);

  useEffect(() => {
    if (!course.id) {
      toast.error("Invalid course details! Redirecting...");
      navigate("/training-course");
    }
    setEmail(userEmail);
    calculateTaxes(discountedPrice);
    loadRazorpayScript();
  }, [course, discountedPrice]);

  const loadRazorpayScript = () => {
    return new Promise((resolve, reject) => {
      if (window.Razorpay) {
        resolve(true);
        return;
      }

      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.async = true;

      script.onload = () => resolve(true);
      script.onerror = () => {
        toast.error("Razorpay SDK failed to load. Check your internet connection.");
        reject(false);
      };

      document.body.appendChild(script);
    });
  };

  const calculateTaxes = (price) => {
    const gstAmount = price * 0.18;
    const cstAmount = price * 0.02;
    setGst(gstAmount);
    setCst(cstAmount);
    setTotalAmount(price + gstAmount + cstAmount);
  };

  const applyDiscount = async () => {
    if (!discountCode.trim()) {
      toast.error("Please enter a discount code!");
      return;
    }
    setApplyingDiscount(true);

    try {
      const response = await axiosInstance.post(
        "/api/discounts/apply",
        { courseId: course.id, discountCode },
        { headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` } }
      );

      if (response.status === 200) {
        setDiscountedPrice(response.data.discountedPrice);
        calculateTaxes(response.data.discountedPrice);
        toast.success("Discount applied successfully!");
      } else {
        toast.error(response.data?.error || "Invalid discount code!");
      }
    } catch (error) {
      toast.error(error.response?.data?.error || "Failed to apply discount!");
    } finally {
      setApplyingDiscount(false);
    }
  };

  const handlePayment = async () => {
    if (!fullName.trim()) return toast.error("Full name is required");
    if (!email) return toast.error("Email is required");
    if (!mobile || mobile.length !== 10) return toast.error("Enter a valid 10-digit mobile number");
    if (!course.id || !userId) return toast.error("Invalid course or user ID!");

    setPaymentProcessing(true);

    try {
      const response = await axiosInstance.post(
        "/api/payments/create",
        {
          userId,
          courseId: course.id,
          amount: totalAmount,
          fullName,
          email,
          mobile,
        },
        { headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` } }
      );

      const { orderId, amount, currency, key } = response.data;

      if (!window.Razorpay) {
        toast.error("Razorpay SDK failed to load.");
        setPaymentProcessing(false);
        return;
      }

      const options = {
        key,
        amount,
        currency,
        name: "Encoders Software Pvt Ltd",
        description: `Payment for ${course.title}`,
        order_id: orderId,
        handler: async (paymentResponse) => {
          toast.success("Payment Successful!");
          try {
            const verifyResponse = await axiosInstance.post(
              "/api/payments/verify",
              paymentResponse,
              { headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` } }
            );

            if (verifyResponse.data === "Payment Successful") {
              localStorage.setItem("paymentSuccess", "true");
              setPaymentDetails({
                transactionId: paymentResponse.razorpay_payment_id,
                amount: totalAmount,
                email,
                courseTitle: course.title,
              });

              setRedirecting(true);
              setTimeout(() => navigate("/training-course"), 3000);
            } else toast.error("Payment verification failed!");
          } catch {
            toast.error("Payment verification failed!");
          } finally {
            setPaymentProcessing(false);
          }
        },
        prefill: { name: fullName, email, contact: mobile },
        theme: { color: "#3399cc" },
      };

      const razorpay = new window.Razorpay(options);
      razorpay.open();
      razorpay.on("payment.failed", () => setPaymentProcessing(false));
    } catch {
      toast.error("Payment initiation failed!");
      setPaymentProcessing(false);
    }
  };

  return (
    <div className="merchantpay-container">
      {redirecting ? (
        <div className="full-page-loader">
          <div className="loader"></div>
          <p>Redirecting to Training Course... Please wait</p>
        </div>
      ) : (
        <div className="merchantpay-box">
          <button className="back-btn" onClick={() => navigate(-1)}>← Back</button>
          <h1>Payment</h1>
          <h3>Course: {course.title}</h3>

          <p><strong>Original Price:</strong> ₹{course.originalPrice}</p>
          <p><strong>Discounted Price:</strong> ₹{discountedPrice}</p>
          <p><strong>GST (18%):</strong> ₹{gst.toFixed(2)}</p>
          <p><strong>CST (2%):</strong> ₹{cst.toFixed(2)}</p>
          <p><strong>Total Amount:</strong> ₹{totalAmount.toFixed(2)}</p>

          <div className="discount-container">
            <input class="discount-input" type="text" placeholder="Discount Code" value={discountCode} onChange={(e) => setDiscountCode(e.target.value)} />
            <button class="apply-btn" onClick={applyDiscount} disabled={applyingDiscount}>{applyingDiscount ? "Applying..." : "Apply"}</button>
          </div>

          <input type="text" placeholder="Enter Full Name" value={fullName} onChange={(e) => setFullName(e.target.value)} />
          <input type="email" placeholder="Email" value={email} readOnly />
          <input type="tel" placeholder="Enter Mobile Number" value={mobile} onChange={(e) => setMobile(e.target.value)} />

          <button class="pay-btn" onClick={handlePayment} disabled={paymentProcessing}>{paymentProcessing ? "Processing..." : `Pay ₹${totalAmount.toFixed(2)}`}</button>
        </div>
      )}

      {paymentDetails && (
        <div className="payment-success-popup">
          <h2>Payment Successful 🎉</h2>
          <p><strong>Transaction ID:</strong> {paymentDetails.transactionId}</p>
          <p><strong>Course:</strong> {paymentDetails.courseTitle}</p>
          <p><strong>Email:</strong> {paymentDetails.email}</p>
          <p><strong>Amount Paid:</strong> ₹{paymentDetails.amount}</p>
        </div>
      )}
      <ToastContainer position="top-right" autoClose={3000} />
    </div>
  );
}
