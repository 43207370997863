import React, { useEffect, useState } from "react";
import axiosInstance from "./axiosInstance";
import { Table, Input, Button, Select, message, Checkbox } from "antd";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import debounce from "lodash.debounce";

const { Option } = Select;

const PaymentDetails = () => {
  const [payments, setPayments] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [statusFilter, setStatusFilter] = useState("");
  const [selectedColumns, setSelectedColumns] = useState(["id", "fullName", "amount", "status", "transactionId"]);

  const columnsConfig = [
    { title: "ID", dataIndex: "id", key: "id" },
    { title: "Full Name", dataIndex: "fullName", key: "fullName" },
    { title: "Amount", dataIndex: "amount", key: "amount", sorter: (a, b) => a.amount - b.amount },
    { title: "Status", dataIndex: "status", key: "status" },
    { title: "Transaction ID", dataIndex: "transactionId", key: "transactionId" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Mobile", dataIndex: "mobile", key: "mobile" },
    { title: "Created At", dataIndex: "createdAt", key: "createdAt" }
  ];

  const filteredColumns = columnsConfig.filter(col => selectedColumns.includes(col.dataIndex));

  const fetchPayments = debounce(async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get("/api/payments", {
        params: { page: page - 1, size: pageSize, search, status: statusFilter }
      });
      setPayments(response.data.content);
      setTotal(response.data.totalElements);
    } catch (error) {
      message.error("Error fetching payments.");
    }
    setLoading(false);
  }, 500);

  useEffect(() => {
    fetchPayments();
  }, [page, pageSize, search, statusFilter]);

  const handleDownloadReport = async () => {
    try {
      const response = await axiosInstance.get("/api/payments/report", { responseType: "blob" });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "payments_report.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch {
      message.error("Error downloading report.");
    }
  };

  const handleColumnChange = (checkedValues) => {
    setSelectedColumns(checkedValues);
  };

  return (
    <div className="payment-container">
      <div className="header-container">
        <Input
          placeholder="Search by Name or Email"
          prefix={<SearchOutlined />}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Select
          placeholder="Filter by Status"
          value={statusFilter}
          onChange={(value) => setStatusFilter(value)}
          allowClear
        >
          <Option value="Success">Success</Option>
          <Option value="Failed">Failed</Option>
          <Option value="Pending">Pending</Option>
        </Select>
        <Button icon={<DownloadOutlined />} onClick={handleDownloadReport}>
          Download Report
        </Button>
      </div>
      <div className="column-selector">
        <Checkbox.Group
          options={columnsConfig.map(col => ({ label: col.title, value: col.dataIndex }))}
          value={selectedColumns}
          onChange={handleColumnChange}
        />
      </div>
      <Table
        columns={filteredColumns}
        dataSource={payments}
        loading={loading}
        pagination={{
          current: page,
          total: total,
          pageSize: pageSize,
          showSizeChanger: true,
          onChange: (newPage, newSize) => {
            setPage(newPage);
            setPageSize(newSize);
          }
        }}
        rowKey="id"
      />
    </div>
  );
};

export default PaymentDetails;