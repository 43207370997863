import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {
  FaUser,
  FaSignOutAlt,
  FaTachometerAlt,
  FaBell,
  FaFileAlt,
  FaBars,
  FaArrowLeft,
} from "react-icons/fa";
import ManageNews from "./ManageNews";
import ManageFAQs from "./ManageFAQs";
import JobServices from "./JobServices";
import UserProfile from "./UserProfile";
import UserManage from "./UserManage";
import ApplicationManagement from "./ApplicationManagement";
import ProjectServices from "./ProjectServices";
import ManageServices from "./ManageServices";
import Categories from "./Categories";
import ContactDetails from "./ContactDetails";
import AdminDashboard from "./AdminDashboard";

import PayslipForm from "./PayslipForm";
import CourseManagement from "./CourseManagement";

import DiscountGenerator from "./DiscountGenerator";




import Blogs from "./Blogs";
import ManageTeam from "./ManageTeam";


import ViewApplications from "./ViewApplications";
import ManageTestimonials from "./ManageTestimonials";
import "./HomePage.css";
import decrypt from "../../include/decrypt";
import PaymentDetails from "./PaymentDetails";


const HomePage = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({ email: "", role: "Guest" });
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [activeMenu, setActiveMenu] = useState("Dashboard");
  const [sidebarOpen, setSidebarOpen] = useState(true);
  useEffect(() => {
    const authenticateUser = () => {
      const queryParams = new URLSearchParams(window.location.search);

      const accessToken = decrypt(queryParams.get("accessToken"));
      const email = decrypt(queryParams.get("email"));
      const role = decrypt(queryParams.get("role"));
      const refreshToken = decrypt(queryParams.get("refreshToken"));

      if (accessToken && email && role) {
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("role", role);
        localStorage.setItem("email", email);
        

        setUserData({ email: email, role: role });
        setIsAuthenticated(true);
        setIsLoading(false);
        if(role === "ADMIN")
        {
          navigate("/HomePage");

        }else{
          const redirectTo = location.state?.redirectTo || "/";
          const job = location.state?.job;
         // alert(job+""+redirectTo);
          if (redirectTo === "/apply" && job) {
            navigate(redirectTo, { state: { job } });
          } else {
            navigate(redirectTo);
          }
        }
       
      } else {
       
        const sessionEmail = localStorage.getItem("email");
        const sessionRole = localStorage.getItem("role");
        const sessionToken = localStorage.getItem("accessToken");
        const sessionRefreshToken = localStorage.getItem("refreshToken");

        if (sessionEmail && sessionToken && sessionRole) {

          setUserData({ email: sessionEmail, role: sessionRole });
          setIsAuthenticated(true);
          setIsLoading(false);
          
        } else {
          setIsAuthenticated(false);
          setIsLoading(false);
        }
      }//close else
    };

    authenticateUser();
  }, [navigate]);

  // Handle logout
  const handleLogout = () => {
    // Clear both sessionStorage and localStorage
    sessionStorage.clear();
    localStorage.clear();
    Cookies.remove("accessEmailCookie");
    Cookies.remove("accessRoleCookie");
    navigate("/LoginSignup");
  };

  const renderMenuOptions = () => {
  
    if (userData.role === "ADMIN") {
      return (
        <ul className="menu-options">
          <li onClick={() => setActiveMenu("Dashboard")}>
            <FaTachometerAlt /> Dashboard
          </li>
          <li onClick={() => setActiveMenu("ManageNews")}>
            <FaFileAlt /> Manage News
          </li>
          <li onClick={() => setActiveMenu("ManageTestimonials")}>
            <FaFileAlt /> Manage Testimonials
          </li>
          <li onClick={() => setActiveMenu("ManageFAQs")}>
            <FaFileAlt /> Manage FAQs
          </li>
          <li onClick={() => setActiveMenu("ManageServices")}>
            <FaFileAlt /> Manage Services
          </li>
          <li onClick={() => setActiveMenu("ProjectServices")}>
            <FaFileAlt /> Project Services
          </li>
          <li onClick={() => setActiveMenu("JobServices")}>
            <FaFileAlt /> Job Services
          </li>
          <li onClick={() => setActiveMenu("ApplicationManagement")}>
            <FaFileAlt /> Application Management
          </li>
          <li onClick={() => setActiveMenu("ManageTeam")}>
            <FaFileAlt /> Manage Team
          </li>
          <li onClick={() => setActiveMenu("UserManage")}>
            <FaFileAlt /> Manage User
          </li>
          <li onClick={() => setActiveMenu("ViewApplications")}>
            <FaFileAlt /> View Applications status
          </li>

          <li onClick={() => setActiveMenu("Categories")}>
            <FaFileAlt /> Blog Categories Management
          </li>
          <li onClick={() => setActiveMenu("Blogs")}>
            <FaFileAlt /> Blog  Management
          </li>

          <li onClick={() => setActiveMenu("ContactDetails")}>
            <FaFileAlt /> Message By Customers
          </li>

          <li onClick={() => setActiveMenu("AdminDashboard")}>
            <FaFileAlt /> Admin Dashboard
          </li>

          <li onClick={() => setActiveMenu("PayslipForm")}>
            <FaFileAlt /> Payslip Generation
          </li>

          <li onClick={() => setActiveMenu("CourseManagement")}>
            <FaFileAlt />Course Management
            </li>


            <li onClick={() => setActiveMenu("DiscountGenerator")}>
            <FaFileAlt />Discount Code Generater
            </li>

            <li onClick={() => setActiveMenu("PaymentDetails")}>
            <FaFileAlt />Payment Details
            </li>


            

          
          
        </ul>
      );
    } else if (userData.role === "USER") {
      return (
        <ul className="menu-options">
          <li onClick={() => setActiveMenu("Dashboard")}>
            <FaTachometerAlt /> Dashboard
          </li>
          <li onClick={() => setActiveMenu("UserProfile")}>
            <FaFileAlt /> Update Profile
          </li>
          <li onClick={() => setActiveMenu("ViewApplications")}>
            <FaFileAlt /> Job & Offer Letter Status
          </li>


          
        </ul>
      );
    } else {
      handleLogout();
    }
  };

  const renderMainContent = () => {
    switch (activeMenu) {
      case "ManageNews":
        return <ManageNews />;
      case "ManageTestimonials":
        return <ManageTestimonials />;
      case "ManageFAQs":
        return <ManageFAQs />;
      case "ManageServices":
        return <ManageServices />;
      case "ProjectServices":
        return <ProjectServices />;
      case "JobServices":
        return <JobServices />;
      case "ManageTeam":
        return <ManageTeam />;
      case "UserManage":
        return <UserManage />;
      case "ViewApplications":
        return <ViewApplications />;
        case "ApplicationManagement":
          return <ApplicationManagement />;

          case "Categories":
            return <Categories />;


          case "Blogs":
            return <Blogs />;

            case "ContactDetails":
            return <ContactDetails />;


            case "AdminDashboard":
              return <AdminDashboard />;

              case "PayslipForm":
                return <PayslipForm />;

                case "CourseManagement":

                return <CourseManagement/>;

                case "DiscountGenerator":

                return <DiscountGenerator/>;

                case "PaymentDetails":

                return <PaymentDetails/>;

                
                

      case "UserProfile":
        return  <UserProfile
        loggedInEmail={userData.email}
        accessToken={localStorage.getItem("accessToken") || sessionStorage.getItem("token")}
    />
      case "Dashboard":
        return <div>Welcome to the {userData.role} Dashboard!</div>;
      default:
        return <div>Select a menu option to get started.</div>;
    }
  };

  const handleBack = () => {
    navigate("/");
  };

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="homepage-container">
      {/* Top Bar */}
      <div className="top-bar">
      <button className="back-btn" onClick={handleBack}>
          <FaArrowLeft /> Back
        </button>
        <div className="user-info">
          <FaUser /> {userData.email} | Role: {userData.role}
        </div>
        <button className="logout-btn" onClick={handleLogout}>
          <FaSignOutAlt /> Logout
        </button>
      </div>

      {/* Layout */}
      <div className="layout">
        {/* Sidebar */}
        <div className={`sidebar ${sidebarOpen ? "open" : "collapsed"}`}>
          <div className="sidebar-header">
            <h3>Menu</h3>
            <button
              className="toggle-btn"
              onClick={() => setSidebarOpen(!sidebarOpen)}
            >
              <FaBars />
            </button>
          </div>
          {renderMenuOptions()}
        </div>

        {/* Main Content */}
        <div className="main-content">{renderMainContent()}</div>
      </div>
    </div>
  );
};

export default HomePage;
