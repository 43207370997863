import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Quill Styles
import axiosInstance from "./axiosInstance";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./CourseManagement.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Button = ({ children, className, ...props }) => (
  <button className={`btn ${className}`} {...props}>{children}</button>
);

const Card = ({ children, className }) => (
  <div className={`card ${className}`}>{children}</div>
);

const Input = ({ className, ...props }) => (
  <input className={`input ${className}`} {...props} />
);

const Table = ({ children, className }) => (
  <table className={`table ${className}`}>{children}</table>
);

export default function CourseManagement() {
  const sessionEmail = localStorage.getItem("email");
  const token = localStorage.getItem("accessToken");

  const [courses, setCourses] = useState([]);
  const [images, setImages] = useState({});
  const [loadingImages, setLoadingImages] = useState({});
  const [course, setCourse] = useState({
    title: "",
    description: "",
    startTime: new Date(),
    endTime: new Date(),
    originalPrice: "",
    image: "",
  });
  const [editingId, setEditingId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [size] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchCourses(0);
  }, []);

  const fetchCourses = async (pageNum) => {
    try {
      const response = await axiosInstance.get(`/api/courses/search?page=${pageNum}&size=${size}&title=${searchTerm}`);
      if (!response.data || !Array.isArray(response.data.content)) {
        throw new Error("Invalid response format");
      }
      setCourses(response.data.content);
      setTotalPages(response.data.totalPages);
      setPage(pageNum);

      response.data.content.forEach((course) => {
        fetchCourseImage(course.id);
      });
    } catch (error) {
      toast.error("Failed to fetch courses");
    }
  };

  const fetchCourseImage = async (id) => {
    try {
      const response = await axiosInstance.get(`/api/courses/${id}/image`, { responseType: "blob" });
      const imageUrl = URL.createObjectURL(response.data);
      setImages((prevImages) => ({ ...prevImages, [id]: imageUrl }));
    } catch (error) {
      setImages((prevImages) => ({ ...prevImages, [id]: null }));
    }
  };

  const handleSearch = () => {
    fetchCourses(0);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCourse((prev) => ({ ...prev, [name]: value }));
  };

  const handleDescriptionChange = (value) => {
    setCourse((prev) => ({ ...prev, description: value }));
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setCourse((prev) => ({ ...prev, image: reader.result.split(",")[1] }));
    };
  };

  const saveCourse = async () => {
    if (!token) {
      toast.error("Unauthorized! Please log in first.");
      return;
    }

    if (!sessionEmail) {
      toast.error("No user email found! Please log in.");
      return;
    }

    try {
      const courseData = {
        title: course.title,
        description: course.description,
        startTime: course.startTime,
        endTime: course.endTime,
        originalPrice: course.originalPrice,
        imageBase64: course.image,
        email: sessionEmail,
      };

      if (editingId) {
        await axiosInstance.put(`/api/courses/update/${editingId}`, courseData);
        toast.success("Course updated successfully");
      } else {
        await axiosInstance.post("/api/courses/create", courseData);
        toast.success("Course added successfully");
      }

      resetCourseForm();
      fetchCourses(page);
    } catch (error) {
      toast.error("Error saving course");
    }
  };

  const editCourse = (course) => {
    setCourse({
      title: course.title,
      description: course.description,
      startTime: new Date(course.startTime),
      endTime: new Date(course.endTime),
      originalPrice: course.originalPrice,
      image: "", // Reset for a new upload
    });

    setEditingId(course.id);

    if (images[course.id]) {
      setCourse((prev) => ({ ...prev, image: images[course.id] }));
    }
  };

  const deleteCourse = async (id) => {
    try {
      await axiosInstance.delete(`/api/courses/delete/${id}`);
      toast.success("Course deleted successfully");
      fetchCourses(page);
    } catch (error) {
      toast.error("Error deleting course");
    }
  };

  const resetCourseForm = () => {
    setCourse({
      title: "",
      description: "",
      startTime: new Date(),
      endTime: new Date(),
      originalPrice: "",
      image: "",
    });
    setEditingId(null);
  };

  return (
    <div className="course-container">
      <h1 className="course-title">Course Management</h1>

      <Card className="course-card">
        <Input name="title" placeholder="Title" value={course.title} onChange={handleInputChange} />

        {/* ✅ Rich Text Editor for Description */}
        <ReactQuill
          value={course.description}
          onChange={handleDescriptionChange}
          theme="snow"
          className="rich-text-editor"
        />

        <DatePicker selected={course.startTime} onChange={(date) => setCourse((prev) => ({ ...prev, startTime: date }))} className="input" />
        <DatePicker selected={course.endTime} onChange={(date) => setCourse((prev) => ({ ...prev, endTime: date }))} className="input" />

        <Input name="originalPrice" placeholder="Price" type="number" value={course.originalPrice} onChange={handleInputChange} />

        <Input type="file" accept="image/*" onChange={handleImageUpload} />

        {course.image && <img src={course.image} alt="Course Preview" className="image-preview" />}

        <Button className="course-btn" onClick={saveCourse} disabled={!token}>
          {editingId ? "Update Course" : "Add Course"}
        </Button>
      </Card>

      <div className="course-table-container">
        <Table>
          <thead>
            <tr>
              <th>Title</th>
              <th>Description</th>
              <th>Image</th>
              <th>Price</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {courses.map((course) => (
              <tr key={course.id}>
                <td>{course.title}</td>
                <td dangerouslySetInnerHTML={{ __html: course.description.substring(0, 100) + "..." }}></td>
                <td>{images[course.id] ? <img src={images[course.id]} alt="Course" className="image-preview" /> : "No Image"}</td>
                <td>₹{course.originalPrice}</td>
                <td>
                  <Button className="edit" onClick={() => editCourse(course)}>Edit</Button>
                  <Button className="delete" onClick={() => deleteCourse(course.id)}>Delete</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <ToastContainer position="top-right" autoClose={3000} />
    </div>
  );
}
