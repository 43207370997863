import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './AdminDashboard.css';

/**
 * ColumnSelector Component
 * @param {Object} props - Props passed from parent component
 * @param {Array} allColumns - List of all available columns
 * @param {Array} selectedColumns - Currently selected columns
 * @param {Function} setSelectedColumns - Function to update selected columns
 */
const ColumnSelector = ({ allColumns, selectedColumns, setSelectedColumns }) => {
  const [tempSelectedColumns, setTempSelectedColumns] = useState([...selectedColumns]);
  const [searchTerm, setSearchTerm] = useState('');

  /**
   * Toggle column selection
   * @param {string} column - Column key to toggle
   */
  const toggleColumn = (column) => {
    setTempSelectedColumns((prev) =>
      prev.includes(column) ? prev.filter((col) => col !== column) : [...prev, column]
    );
  };

  /**
   * Apply the selected columns
   */
  const handleApply = () => {
    setSelectedColumns(tempSelectedColumns);
  };

  /**
   * Select All Columns
   */
  const handleSelectAll = () => {
    setTempSelectedColumns([...allColumns]);
  };

  /**
   * Deselect All Columns
   */
  const handleDeselectAll = () => {
    setTempSelectedColumns([]);
  };

  /**
   * Filtered Columns for Search Functionality
   */
  const filteredColumns = allColumns.filter((col) =>
    col.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="column-selector">
      <h4>Select Columns</h4>

      {/* Search Box */}
      <input
        type="text"
        placeholder="Search columns..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      {/* Column List */}
      <div className="column-list">
        {filteredColumns.length > 0 ? (
          filteredColumns.map((col) => (
            <div key={col} className="column-item">
              <input
                type="checkbox"
                checked={tempSelectedColumns.includes(col)}
                onChange={() => toggleColumn(col)}
              />
              {col}
            </div>
          ))
        ) : (
          <p className="no-results">No matching columns found</p>
        )}
      </div>

      {/* Select All / Deselect All / Apply Buttons */}
      <div className="column-actions">
        <button onClick={handleSelectAll} className="select-all">Select All</button>
        <button onClick={handleDeselectAll} className="deselect-all">Deselect All</button>
        <button onClick={handleApply} className="apply-button">Apply</button>
      </div>
    </div>
  );
};

/**
 * PropTypes Validation
 */
ColumnSelector.propTypes = {
  allColumns: PropTypes.array.isRequired,
  selectedColumns: PropTypes.array.isRequired,
  setSelectedColumns: PropTypes.func.isRequired,
};

export default ColumnSelector;
