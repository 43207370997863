import React, { createContext, useState, useContext, useEffect } from "react";

const ImageCacheContext = createContext();

export const ImageCacheProvider = ({ children }) => {
  const [imageCache, setImageCache] = useState(() => {
    try {
      const cachedData = JSON.parse(localStorage.getItem("imageCache")) || {};
      // Remove expired entries
      const now = Date.now();
      const validCache = Object.fromEntries(
        Object.entries(cachedData).filter(([, { expiry }]) => now < expiry)
      );
      return validCache;
    } catch (error) {
      console.error("Error loading image cache:", error);
      return {};
    }
  });

  useEffect(() => {
    localStorage.setItem("imageCache", JSON.stringify(imageCache));
  }, [imageCache]);

  const addImageToCache = (id, imageBlob, ttl = 24 * 60 * 60 * 1000) => {
    if (!imageCache[id]) {
      try {
        const objectURL = URL.createObjectURL(imageBlob);
        const expiry = Date.now() + ttl;
        setImageCache((prevCache) => {
          const updatedCache = {
            ...prevCache,
            [id]: { url: objectURL, expiry },
          };
          localStorage.setItem("imageCache", JSON.stringify(updatedCache));
          return updatedCache;
        });
      } catch (error) {
        console.error("Failed to cache image:", error);
      }
    }
  };

  const getImageFromCache = (id) => {
    const entry = imageCache[id];
    if (entry && Date.now() < entry.expiry) {
      return entry.url;
    } else {
      if (entry) {
        // Remove expired entry
        setImageCache((prevCache) => {
          const { [id]: _, ...rest } = prevCache;
          localStorage.setItem("imageCache", JSON.stringify(rest));
          return rest;
        });
      }
      return null;
    }
  };

  const preloadImages = () => {
    Object.values(imageCache).forEach(({ url }) => {
      const img = new Image();
      img.src = url;
    });
  };

  useEffect(() => {
    preloadImages();
  }, [imageCache]);

  const LoaderImage = () => (
    <div className="loader">
      <img src="/loader.gif" alt="Loading..." />
    </div>
  );

  const getOrFetchImage = async (id, fetchImageFn) => {
    const cachedImage = getImageFromCache(id);
    if (cachedImage) {
      return cachedImage;
    }
    const imageBlob = await fetchImageFn();
    addImageToCache(id, imageBlob);
    return URL.createObjectURL(imageBlob);
  };

  return (
    <ImageCacheContext.Provider value={{ addImageToCache, getImageFromCache, LoaderImage, getOrFetchImage }}>
      {children}
    </ImageCacheContext.Provider>
  );
};

export const useImageCache = () => {
  const context = useContext(ImageCacheContext);
  if (!context) {
    throw new Error("useImageCache must be used within an ImageCacheProvider");
  }
  return context;
};
