import React, { useState, useEffect,useRef } from "react";
import { FaEnvelope, FaLock, FaUser, FaPhone } from "react-icons/fa";
import { useNavigate,useLocation } from "react-router-dom";
import "./LoginSignup.css";
import Cookies from "js-cookie";
import { toast, ToastContainer } from "react-toastify";
import config from "../../include/config";
import axios from "axios";
import encrypt from "../../include/encrypt"


const LoginSignup = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSignup, setIsSignup] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    contactNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
    captchaInput: "", // Ensure this is initialized
  });
  const [captcha, setCaptcha] = useState(""); // Captcha from server
  const [errorMessage, setErrorMessage] = useState({});
  const [generalError, setGeneralError] = useState("");
  const [loading, setLoading] = useState(false);
  const inactivityTimeoutRef = useRef(null);


  const handleGoogleLogin = () => {
    window.location.href = `${config.AUTH_URL}/oauth2/authorization/google`; // Triggers the OAuth2 login
  }

  


  useEffect(() => {
    if (isSignup) {
      fetchCaptcha();
    }
  }, [isSignup]);

  const fetchCaptcha = async () => {
    try {
      const response = await axios.get(`${config.BASE_URL}/api/captcha/generate`);
      setCaptcha(response.data);
    } catch (error) {
      console.error("Error fetching captcha:", error);
      toast.error("Failed to load CAPTCHA. Please try again.");
    }
  };




  useEffect(() => {
  
    const tokenExpiry = sessionStorage.getItem("tokenExpiry");
    if (tokenExpiry && Date.now() >= tokenExpiry) {
      handleLogout();
      toast.error("Session expired. Please log in again.");
    }

    startInactivityTimer();
    window.addEventListener("mousemove", resetInactivityTimer);
    window.addEventListener("keydown", resetInactivityTimer);

    return () => {
      clearTimeout(inactivityTimeoutRef.current);
      window.removeEventListener("mousemove", resetInactivityTimer);
      window.removeEventListener("keydown", resetInactivityTimer);
    };
  }, []);

  const startInactivityTimer = () => {
    if (inactivityTimeoutRef.current) {
      clearTimeout(inactivityTimeoutRef.current);
    }
    inactivityTimeoutRef.current = setTimeout(() => {
      handleLogout();
      toast.info("You have been logged out due to inactivity.");
    }, 30 * 60 * 1000); // 30 minutes
  };

  const resetInactivityTimer = () => {
    startInactivityTimer();
  };



  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    setErrorMessage((prev) => ({ ...prev, [name]: "" }));
    setGeneralError("");
  };

  const validatePasswordPolicy = (password) => {
    const policy = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!])(?=\S+$).{8,}$/;
    return policy.test(password);
  };


  const validateSignup = () => {
    let errors = {};
    
    if (!formData.firstName) errors.firstName = "First Name is required.";
    if (!formData.lastName) errors.lastName = "Last Name is required.";
    if (!formData.email) errors.email = "Email is required.";
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email))
      errors.email = "Invalid email address.";
    if (!formData.contactNumber)
      errors.contactNumber = "Contact Number is required.";
    if (!/^\d{10}$/.test(formData.contactNumber))
      errors.contactNumber = "Invalid contact number.";
    if (!formData.password) errors.password = "Password is required.";
    if (!formData.confirmPassword)
      errors.confirmPassword = "Confirm Password is required.";
    if (!validatePasswordPolicy(formData.password))
      errors.password =
        "Password must be at least 8 characters, contain uppercase, lowercase, number, and special character.";
    if (formData.password !== formData.confirmPassword)
      errors.confirmPassword = "Passwords do not match.";
    
    // CAPTCHA Validation
    if (!formData.captchaInput || formData.captchaInput.trim() === "") 
      errors.captchaInput = "CAPTCHA is required.";
     else if (formData.captchaInput.trim().toLowerCase() !== captcha.toLowerCase()) 
      errors.captchaInput = "CAPTCHA does not match.";
    
  
    return errors;
  };

  const handleSubmit = async () => {
    if (!isSignup) {
      const errors = {};
      if (!formData.email) errors.email = "Email is required.";
      if (!formData.password) errors.password = "Password is required.";
    
      if (Object.keys(errors).length > 0) {
        setErrorMessage(errors);
        fetchCaptcha(); // Refresh the CAPTCHA after failure
        return;
      }
    }



    if (isSignup) {
      const errors = validateSignup();
      if (Object.keys(errors).length > 0) {
        setErrorMessage(errors);
        return;
      }
    }
    setLoading(true);
    const url = isSignup
      ? `${config.BASE_URL}/api/auth/signup`
      : `${config.BASE_URL}/api/auth/login`;

    const payload = isSignup
      ? {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          contactNumber: formData.contactNumber,
          password: formData.password,
          confirmPassword: formData.confirmPassword,
          captcha: formData.captchaInput,
        }
      : {
          email: formData.email,
          password: formData.password,
        };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({data : encrypt(payload)}),
      });

      if (response.ok) {
        const data = await response.json();
         // Extract and store the role name
      const roleName = data.roles && data.roles.length > 0 ? data.roles[0].name : "Guest";
      sessionStorage.setItem("role", roleName);
        if (isSignup) {
        alert( "User registered successfully! Check your email for account active by activation link.");
            navigate("/");
        
        } else {

            sessionStorage.setItem("token", data.accessToken);
            sessionStorage.setItem("refreshToken", data.refreshToken);
            sessionStorage.setItem("role", roleName);
            sessionStorage.setItem("email", formData.email);
            sessionStorage.setItem("loginType", "form");

            localStorage.setItem("accessToken",  data.accessToken);
            localStorage.setItem("refreshToken", data.refreshToken);
            localStorage.setItem("role", roleName);
            localStorage.setItem("email", formData.email);
            localStorage.setItem("loginType", "form");
            setIsAuthenticated(true);
            if(roleName=="ADMIN")
            {
              navigate("/HomePage");

            }
            else{

              const redirectTo = location.state?.redirectTo || "/";
    const job = location.state?.job;

    if (redirectTo === "/apply" && job) {
      navigate(redirectTo, { state: { job } });
    } else {
      navigate(redirectTo);
    }
            }
            

        }
      } else {
        switch (response.status) {
          case 401:
            setGeneralError("Unauthorized. Please check your credentials or activate your account to click forgot password.");
            break;
          case 403:
            setGeneralError("Forbidden. You do not have access.");
            break;
          case 404:
            setGeneralError("API not found. Please contact support.");
            break;
          case 500:
            setGeneralError("Internal server error. Please try again later.");
            break;
          default:
            const error = await response.json();
            setGeneralError(error.message || "An unexpected error occurred.");
        }
      }
    } catch (error) {
      console.error("Error:", error);
      setGeneralError("An unexpected error occurred. Please try again.");
    }
  };

  

  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    setIsAuthenticated(false);
    navigate("/LoginSignup");
    alert("You have been logged out successfully.");
  };

  const toggleSignup = () => {
    setIsSignup((prev) => !prev);
    setFormData({
      firstName: "",
      lastName: "",
      contactNumber: "",
      email: "",
      password: "",
      confirmPassword: "",
      captchaInput: "",
    });
    setErrorMessage({});
    setGeneralError("");


  };



  return (
    <div>
     
    <div className="center-container">
    
  { (
    <button
      className="back-btn"
      onClick={() => navigate('/')}
    >
      ← Back
    </button>
  )}
      <div className="form-container">
        <div className="form-header">
          <img src="/logo.png" alt="Encoders Software Pvt Ltd" height="50" />
          <h1>Candidate Portal</h1>
         
        </div>
        {isAuthenticated ? (
          <p>Welcome back! Use the Logout button to sign out.</p>
        ) : (
          <div>
            <div className="error-message">
              {generalError && <p>{generalError}</p>}
            </div>
            {isSignup ? (
              <>
                <div className="input-group">
                  <span className="icon">
                    <FaUser />
                  </span>
                  <input
                    type="text"
                    placeholder="First Name"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                  />
                  {errorMessage.firstName && (
                    <p className="field-error">{errorMessage.firstName}</p>
                  )}
                </div>
                <div className="input-group">
                  <span className="icon">
                    <FaUser />
                  </span>
                  <input
                    type="text"
                    placeholder="Last Name"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                  />
                  {errorMessage.lastName && (
                    <p className="field-error">{errorMessage.lastName}</p>
                  )}
                </div>
                <div className="input-group">
                  <span className="icon">
                    <FaPhone />
                  </span>
                  <input
                    type="tel"
                    placeholder="Contact Number"
                    name="contactNumber"
                    value={formData.contactNumber}
                    onChange={handleInputChange}
                  />
                  {errorMessage.contactNumber && (
                    <p className="field-error">{errorMessage.contactNumber}</p>
                  )}
                </div>
                <div className="input-group">
                  <span className="icon">
                    <FaEnvelope />
                  </span>
                  <input
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  {errorMessage.email && (
                    <p className="field-error">{errorMessage.email}</p>
                  )}
                </div>
                <div className="input-group">
                  <span className="icon">
                    <FaLock />
                  </span>
                  <input
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                  />
                  {errorMessage.password && (
                    <p className="field-error">{errorMessage.password}</p>
                  )}
                </div>
                <div className="input-group">
                  <span className="icon">
                    <FaLock />
                  </span>
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                  />
                  {errorMessage.confirmPassword && (
                    <p className="field-error">
                      {errorMessage.confirmPassword}
                    </p>
                  )}
                </div>

   {/* CAPTCHA Field */}
   <div className="form-group">
                <label>Captcha *</label>
                <div className="captcha-box">
                  <span className="captcha-text">{captcha}</span>
                  <button
                    type="button"
                    className="refresh-captcha"
                    onClick={fetchCaptcha}
                  >
                    ↻
                  </button>
                </div>
                <input
                  type="text"
                  name="captchaInput"
                  value={formData.captchaInput}
                  onChange={handleInputChange}
                  placeholder="Enter the captcha"
                />
                {errorMessage.captchaInput && (
                  <p className="field-error">{errorMessage.captchaInput}</p>
                )}
              </div>

              <button className="submit-btn" onClick={handleSubmit} disabled={loading}>
            {loading ? "Submitting..." : isSignup ? "Signup" : "Login"}
          </button>
          <div className="signup-link" onClick={() => setIsSignup((prev) => !prev)}>
            {isSignup ? "Already have an account? Login here" : "Don’t have an account? Sign up here"}
          </div>
              </>
            ) : (
              <>
                <div className="input-group">
                  <span className="icon">
                    <FaEnvelope />
                  </span>
                  <input
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  {errorMessage.email && (
                    <p className="field-error">{errorMessage.email}</p>
                  )}
                </div>
                <div className="input-group">
                  <span className="icon">
                    <FaLock />
                  </span>
                  <input
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                  />
                  {errorMessage.password && (
                    <p className="field-error">{errorMessage.password}</p>
                  )}
                </div>
                <div className="forgot-password">
                  <a href="/ForgotPassword">Forgot Password?</a>
                </div>
                <button className="submit-btn" onClick={handleSubmit}>
                  Login
                </button>
                <button
  className="google-login-btn"
  onClick={
    handleGoogleLogin 
  }
>
  <img
    src="/images/google-icon.png"
    alt="Google Logo"
  />
  Login with Google
</button>

                <div className="signup-link" onClick={toggleSignup}>
                  Don’t have an account? <span>Sign up here</span>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
    
    </div>
  );
};

export default LoginSignup;
