import React from "react";
import { useNavigate } from "react-router-dom";

const Slider = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/contact"); // Navigate to Contact Page
    setTimeout(() => {
      let attempts = 0;
      const scrollInterval = setInterval(() => {
        const contactForm = document.querySelector(".contact-form");
        console.log(`Checking for .contact-form... Attempt: ${attempts}`);

        if (contactForm) {
          contactForm.scrollIntoView({ behavior: "smooth", block: "start" });
          clearInterval(scrollInterval); // Stop checking once found
        } else if (attempts > 10) {
          console.error("contact-form not found after multiple attempts!");
          clearInterval(scrollInterval);
        }
        attempts++;
      }, 300); // Check every 300ms
    }, 1000); // Small delay to allow navigation
  };

  return (
    <div>
      {/* Carousel Component */}
      <div
        id="carouselExampleDark"
        className="carousel carousel-dark slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active" data-bs-interval="3000">
            <img
              src="/images/big-images/b2.jpg"
              className="d-block w-100"
              alt="Website Development Services"
            />
            <div className="carousel-caption d-none d-md-block">
              <h5>Innovative Website Development</h5>
              <p>
                Crafting dynamic and responsive websites tailored to your
                business needs. Build your online presence with EncodersSoftware.
              </p>
            </div>
          </div>
          <div className="carousel-item" data-bs-interval="3000">
            <img
              src="/images/big-images/b3.jpg"
              className="d-block w-100"
              alt="Mobile Application Development Services"
            />
            <div className="carousel-caption d-none d-md-block">
              <h5>Mobile Application Solutions</h5>
              <p>
                Delivering user-friendly mobile applications with seamless
                performance. Transform your ideas into reality with our expertise.
              </p>
            </div>
          </div>
          <div className="carousel-item" data-bs-interval="3000">
            <img
              src="/images/big-images/b4.jpg"
              className="d-block w-100"
              alt="Custom IT Solutions and SaaS Products"
            />
            <div className="carousel-caption d-none d-md-block">
              <h5>Customized IT Solutions</h5>
              <p>
                Empower your business with tailored SaaS products and efficient IT
                support services. Partner with EncodersSoftware for excellence.
              </p>
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      {/* Floating "GET A QUOTE" Button */}
      <button className="quote-button" onClick={handleNavigate}>
        GET A QUOTE
      </button>

      {/* CSS for Floating Button */}
      <style>
        {`
         .quote-button {
    position: fixed;
    bottom: 20px;
    left: 20px; /* Change 'right' to 'left' */
    background-color: #ff5733;
    color: white;
    padding: 15px 15px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    text-decoration: none;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    z-index: 1000;
    cursor: pointer;
    border: none;
}

          .quote-button:hover {
            background-color: #d9432e;
          }

          @media (max-width: 768px) {
            .quote-button {
              font-size: 14px;
              padding: 10px 20px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default Slider;
