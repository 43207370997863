import React, { useState, useEffect, useCallback } from 'react';
import AdminControls from './AdminControls';
import ColumnSelector from './ColumnSelector';
import DataTable from './DataTable';
import GraphDisplay from './GraphDisplay';
import Pagination from './Pagination';
import { fetchAllApplications } from './apiService';
import { downloadCSV, downloadPDF, downloadExcel, downloadJSON } from './exportService';
import './AdminDashboard.css';

const AdminDashboard = () => {
  const [applications, setApplications] = useState([]);
  const [filteredApplications, setFilteredApplications] = useState([]);
  const [graphData, setGraphData] = useState(null);
  const graphTypes = ['Bar', 'Line', 'Pie', 'Doughnut', 'Radar'];

  const [selectedColumns, setSelectedColumns] = useState([
    'id', 'firstName', 'lastName', 'mobile', 'city', 'state', 'email', 'status', 'updatedAt',
    'exit_employment', 'selection_status', 'offer_flag'
  ]);
  const [selectedGraphs, setSelectedGraphs] = useState(['Bar']);
  const [statusFilter, setStatusFilter] = useState('');
  const [updateAtFilter, setUpdateAtFilter] = useState('');
  const [exitEmploymentFilter, setExitEmploymentFilter] = useState('');
  const [selectionStatusFilter, setSelectionStatusFilter] = useState('');
  const [offerFlagFilter, setOfferFlagFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Fetch data from API
  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const data = await fetchAllApplications();
      setApplications(data);
      setFilteredApplications(data); // ✅ Ensure data is stored
      updateFilteredApplications(data);
      prepareGraphData(data);
    } catch (err) {
      setError(err.message || 'Failed to fetch applications. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, []);

  // Prepare Graph Data
  const prepareGraphData = (data) => {
    const statusCounts = data.reduce((acc, app) => {
      const status = app.status || 'No Status';
      acc[status] = (acc[status] || 0) + 1;
      return acc;
    }, {});

    setGraphData({
      labels: Object.keys(statusCounts),
      datasets: [{
        label: 'Application Status',
        data: Object.values(statusCounts),
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
        borderColor: '#ddd',
        borderWidth: 1,
      }],
    });
  };

  // Apply Filters Whenever They Change
  const updateFilteredApplications = (data) => {
    const filteredData = data.filter((app) => {
      const statusMatch = !statusFilter || app.status === statusFilter;
      const updateMatch = !updateAtFilter || new Date(app.updatedAt).toISOString().split('T')[0] === updateAtFilter;
      const exitEmploymentMatch = !exitEmploymentFilter || app.exit_employment === Number(exitEmploymentFilter);
      const selectionStatusMatch = !selectionStatusFilter || app.selection_status === Number(selectionStatusFilter);
      const offerFlagMatch = !offerFlagFilter || app.offer_flag === Number(offerFlagFilter);

      return statusMatch && updateMatch && exitEmploymentMatch && selectionStatusMatch && offerFlagMatch;
    });

    setFilteredApplications(filteredData);
    prepareGraphData(filteredData); // ✅ Update Graph Data based on Filtered Data
  };

  // Apply Filters Automatically When Filters Change
  useEffect(() => {
    updateFilteredApplications(applications);
  }, [statusFilter, updateAtFilter, exitEmploymentFilter, selectionStatusFilter, offerFlagFilter, applications]);

  useEffect(() => {
    fetchData();
  }, []); // ✅ Fetch Data Only Once on Component Mount

  // Paginate Data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const paginatedApplications = filteredApplications.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="admin-dashboard-container">
      <h1>Admin Dashboard</h1>
      <AdminControls
        fetchData={fetchData}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
        updateAtFilter={updateAtFilter}
        setUpdateAtFilter={setUpdateAtFilter}
        exitEmploymentFilter={exitEmploymentFilter}
        setExitEmploymentFilter={setExitEmploymentFilter}
        selectionStatusFilter={selectionStatusFilter}
        setSelectionStatusFilter={setSelectionStatusFilter}
        offerFlagFilter={offerFlagFilter}
        setOfferFlagFilter={setOfferFlagFilter}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        allColumns={Object.keys(applications[0] || {})}
        downloadCSV={() => downloadCSV(filteredApplications, selectedColumns)}
        downloadPDF={() => downloadPDF(filteredApplications, selectedColumns)}
        downloadExcel={() => downloadExcel(filteredApplications, selectedColumns)}
        downloadJSON={() => downloadJSON(filteredApplications)}
        graphTypes={graphTypes}
        selectedGraphs={selectedGraphs}
        setSelectedGraphs={setSelectedGraphs}
      />

      <ColumnSelector
        allColumns={Object.keys(applications[0] || {})}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
      />

      <h2>Application Data</h2>
      {loading ? (
        <div className="loading">Loading data...</div>
      ) : error ? (
        <div className="error">{error}</div>
      ) : (
        <>
          <DataTable data={paginatedApplications} selectedColumns={selectedColumns} />
          <Pagination
            currentPage={currentPage}
            totalItems={filteredApplications.length}
            itemsPerPage={itemsPerPage}
            setCurrentPage={setCurrentPage}
          />
        </>
      )}

      <h2>Application Data Visualization</h2>
      {graphData && selectedGraphs.length > 0 ? (
        <GraphDisplay graphData={graphData} selectedGraphs={selectedGraphs} />
      ) : (
        <div className="graph-placeholder">No graph data available</div>
      )}
    </div>
  );
};

export default AdminDashboard;
