import React from "react";
import { FaFilter } from "react-icons/fa";
import "./SearchBar.css";

const FilterPanel = ({ filters = {}, setFilters }) => {
  // Handle dropdown selection
  const handleFilterChange = (e) => {
    setFilters((prev) => ({
      ...prev,
      jobType: e.target.value, // Set the selected job type
    }));
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setFilters((prev) => ({
      ...prev,
      searchQuery: e.target.value, // Update search query
    }));
  };

  return (
    <div className="filter-panel">
      <div className="filter-header">
        <FaFilter className="filter-icon-large" />
        <h3>Filters</h3>
      </div>

      <div className="filter-content">

        {/* Job Type Dropdown */}
        <label>
          <strong>Job Type:</strong>
          <select value={filters.jobType || ""} onChange={handleFilterChange}>
            <option value="">All Jobs</option>
            <option value="Remote">Remote Jobs</option>
            <option value="FullTime">Full-Time</option>
            <option value="PartTime">Part-Time</option>
            <option value="Internship">Internship</option>
          </select>
        </label>
      </div>
    </div>
  );
};

export default FilterPanel;
