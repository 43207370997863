import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "./axiosInstance";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./TrainingCourse.css";
import Navbar from "../../include/Navbar";
import Footer from "../../include/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faArrowLeft, faArrowRight, faTimes, faCheckCircle, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { motion, AnimatePresence } from "framer-motion";
import { debounce } from "lodash";
import ReactQuill from "react-quill"; // Rich text editor for formatted descriptions
import "react-quill/dist/quill.snow.css"; // Quill CSS
import { useLocation } from "react-router-dom";

const Button = ({ children, className, ...props }) => (
  <button className={`btn ${className}`} {...props}>{children}</button>
);

const Loader = () => <div className="loader"></div>;

export default function TrainingCourse() {
  const { course_id } = useParams();
  const [courses, setCourses] = useState([]);
  const [images, setImages] = useState({});
  const [loadingImages, setLoadingImages] = useState({});
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [modalExpanded, setModalExpanded] = useState(false);
  const [page, setPage] = useState(0);
  const [size] = useState(6);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    fetchCourses(page);
  }, [page, searchQuery]);


  useEffect(() => {
    setTimeout(() => {
      const contactForm = document.querySelector(".course-title");
      console.log("Attempting to scroll to .course-title...");

      if (contactForm) {
        contactForm.scrollIntoView({ behavior: "smooth", block: "start" });
      } else {
        console.error("course-titlenot found!");
      }
    }, 500); // Small delay to ensure rendering completes
  }, [location]);

  useEffect(() => {
    if (course_id && courses.length > 0) {
      const foundCourse = courses.find(course => course.id.toString() === course_id);
      if (foundCourse) {
        setSelectedCourse(foundCourse);
      }
    }
  }, [courses, course_id]);

  const fetchCourses = async (pageNum) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `/api/courses/search?page=${pageNum}&size=${size}&title=${searchQuery}`
      );
      if (response.data && response.data.content) {
        setCourses(response.data.content);
        setTotalPages(response.data.totalPages);

        response.data.content.forEach((course) => {
          setLoadingImages((prev) => ({ ...prev, [course.id]: true }));
          fetchCourseImage(course.id);
        });
      } else {
        setCourses([]);
        setTotalPages(1);
      }
    } catch (error) {
      toast.error("Failed to fetch courses. Please try again.");
    }
    setLoading(false);
  };

  const fetchCourseImage = async (id) => {
    try {
      const response = await axiosInstance.get(`/api/courses/${id}/image`, { responseType: "blob" });
      const imageUrl = URL.createObjectURL(response.data);
      setImages((prevImages) => ({ ...prevImages, [id]: imageUrl }));
    } catch (error) {
      setImages((prevImages) => ({ ...prevImages, [id]: null }));
    } finally {
      setLoadingImages((prev) => ({ ...prev, [id]: false }));
    }
  };

  const debouncedSearch = useCallback(
    debounce((query) => setSearchQuery(query), 500),
    []
  );

  const handleSearchChange = (e) => {
    debouncedSearch(e.target.value);
  };

  const toggleDescription = (id) => {
    setExpandedDescriptions((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const openPopup = (course) => {
    setSelectedCourse(course);
    setModalExpanded(false);
    navigate(`/training-course/${course.id}`);
  };

  const closePopup = () => {
    setSelectedCourse(null);
    navigate(`/training-course`);
  };

  const applyCourse = (course) => {
   
    const isLoggedIn = localStorage.getItem("accessToken");
    if (isLoggedIn) {
      navigate("/pay", { state: { course } });
    } else {
      navigate("/LoginSignup", { state: { redirectTo: "/pay", course } });
    }


  };

  return (
    <div>
      <Navbar />
      <div className="course-container">
        <h1 className="course-title">Explore Our Training Courses</h1>

        <div className="search-bar">
          <FontAwesomeIcon icon={faSearch} className="icon" />
          <input type="text" placeholder="Search Courses..." onChange={handleSearchChange} />
        </div>

        {loading ? (
          <Loader />
        ) : (
          <div className="course-list">
            {courses.length > 0 ? (
              courses.map((course) => (
                <motion.div
                  key={course.id}
                  className="course-card"
                  whileHover={{ scale: 1.05 }}
                  transition={{ type: "spring", stiffness: 200 }}
                >
                  <div className="image-container">
                    {loadingImages[course.id] ? (
                      <Loader />
                    ) : images[course.id] ? (
                      <img src={images[course.id]} alt={course.title} className="course-image" />
                    ) : (
                      <div className="no-image">No Image Available</div>
                    )}
                  </div>

                  <h3>{course.title}</h3>
                  <div className="course-description">
                    <ReactQuill
                      value={expandedDescriptions[course.id] ? course.description : `${course.description.substring(0, 100)}...`}
                      readOnly
                      theme="bubble"
                    />
                    <span className="read-toggle" onClick={() => toggleDescription(course.id)}>
                      {expandedDescriptions[course.id] ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />} {expandedDescriptions[course.id] ? "Read Less" : "Read More"}
                    </span>
                  </div>

                  <Button className="apply-btn" onClick={() => applyCourse(course)}>🚀 Apply </Button>
                </motion.div>
              ))
            ) : (
              <p className="no-courses">No courses available.</p>
            )}
          </div>
        )}

        <Footer />
        <ToastContainer position="top-right" autoClose={3000} />

        <AnimatePresence>
          {selectedCourse && (
            <motion.div className="modal-overlay" onClick={closePopup} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <motion.div className="modal-content" onClick={(e) => e.stopPropagation()} initial={{ scale: 0.8 }} animate={{ scale: 1 }} exit={{ scale: 0.8 }}>
                <button className="close-btn" onClick={closePopup}>
                  <FontAwesomeIcon icon={faTimes} />
                </button>
                <h2 className="modal-title">{selectedCourse.title}</h2>

                <ReactQuill value={modalExpanded ? selectedCourse.description : `${selectedCourse.description.substring(0, 100)}...`} readOnly theme="bubble" />
                <span className="read-toggle" onClick={() => setModalExpanded(!modalExpanded)}>
                  {modalExpanded ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />} {modalExpanded ? "Read Less" : "Read More"}
                </span>

                <Button className="apply-btn" onClick={() => applyCourse(selectedCourse)}>🚀 Apply Now</Button>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}
