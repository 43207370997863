import React, { useEffect, useState } from "react";
import "./Footer.css";
import axios from "axios";
import config from "./config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faLinkedin,
  faInstagram,
  faWhatsapp,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faPhone,
  faMapMarkerAlt,
  faGlobe,
  faMobileAlt,
  faCloud,
  faServer,
  faProjectDiagram,
  faCertificate,
} from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  const [ipInfo, setIpInfo] = useState({ ip: "Loading...", visitCount: 0 });
  const [email, setEmail] = useState("");
  const [cookiesAccepted, setCookiesAccepted] = useState(
    localStorage.getItem("cookiesAccepted") === "true"
  );
  const [subscriptionStatus, setSubscriptionStatus] = useState("");

  // Fetch User IP and Visit Count
  useEffect(() => {
    const trackUser = async () => {
      try {
        const response = await axios.get(`${config.BASE_URL}/api/tracker/track`);
        const [ip, count] = response.data.split(", ").map((item) => item.split(": ")[1]);
        setIpInfo({ ip, visitCount: parseInt(count) });
      } catch (error) {
        console.error("Failed to track user:", error);
        setIpInfo({ ip: "Unavailable", visitCount: 0 });
      }
    };

    trackUser();
  }, []);

  // Newsletter Subscription API
  const handleNewsletterSubscription = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || !emailRegex.test(email)) {
      setSubscriptionStatus("Please enter a valid email address!");
      return;
    }
    try {
      const response = await axios.post(`${config.BASE_URL}/api/newsletter/subscribe`, { email });
      setSubscriptionStatus(response.data);
      setEmail("");
    } catch (error) {
      console.error("Subscription failed:", error);
      setSubscriptionStatus("Subscription failed. Please try again later.");
    }
  };

  // Cookies Consent
  const acceptCookies = () => {
    localStorage.setItem("cookiesAccepted", "true");
    setCookiesAccepted(true);
  };

  // Services List
  const services = [
    { icon: faGlobe, name: "Web Development" },
    { icon: faMobileAlt, name: "Mobile App Development" },
    { icon: faCloud, name: "Cloud Integration" },
    { icon: faServer, name: "API Development" },
    { icon: faProjectDiagram, name: "IT Consulting" },
    { icon: faServer, name: "Corporate Training" },
    { icon: faProjectDiagram, name: "College and University Projects" },
    { icon: faProjectDiagram, name: "School-Level Projects" },
    { icon: faProjectDiagram, name: "Government Projects" },
  ];

  return (
    <>
      {/* Cookies Consent */}
      {!cookiesAccepted && (
        <div className="cookies-banner">
          <p>
            🍪 We use cookies to improve your experience on our site. By using our website, you agree to our{" "}
            <a href="/privacy-policy">Privacy Policy</a>.
          </p>
          <button onClick={acceptCookies}>Accept</button>
        </div>
      )}

      {/* Footer */}
      <footer className="footer">
        <div className="footer-container">
          {/* About Section */}
          <div className="footer-section about">
            <h3>About Us</h3>
            <p>
              <strong>Encoders Software Private Limited</strong>, founded in 2019, is a leading IT solutions provider
              headquartered in Ranchi, Jharkhand, India. We specialize in innovative digital solutions tailored to
              businesses' unique needs.
            </p>
            <ul className="company-details">
              <li><strong>CIN:</strong> U74999JH2019PTC012902</li>
              <li><strong>Authorized Capital:</strong> ₹10,00,000</li>
              <li><strong>Paid-Up Capital:</strong> ₹1,00,000</li>
              <li><strong>Registration Number:</strong> 12902</li>
            </ul>
          </div>

          {/* ISO Certification Section */}
          <div className="footer-section certification">
            <h3>ISO 9001:2015 Certified</h3>
            <p>
              <FontAwesomeIcon icon={faCertificate} /> <strong>Quality Management System</strong>
            </p>
            <p>License No: QMS/230620/3778</p>
            <p>Valid Until: 27th December 2027</p>
          </div>

          {/* Services Section */}
          <div className="footer-section services">
            <h3>Our Services</h3>
            <ul>
              {services.map((service, index) => (
                <li key={index}>
                  <FontAwesomeIcon icon={service.icon} /> {service.name}
                </li>
              ))}
            </ul>
          </div>

          {/* Newsletter Section */}
          <div className="footer-section newsletter">
            <h3>Subscribe to Our Newsletter</h3>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button onClick={handleNewsletterSubscription}>Subscribe</button>
            {subscriptionStatus && <p>{subscriptionStatus}</p>}
          </div>

          {/* Social Media */}
          <div className="footer-section follow-us">
            <h3>Follow Us</h3>
            <div className="social-icons">
              <a href="#"><FontAwesomeIcon icon={faFacebook} /></a>
              <a href="#"><FontAwesomeIcon icon={faTwitter} /></a>
              <a href="#"><FontAwesomeIcon icon={faLinkedin} /></a>
              <a href="https://www.instagram.com/encoders_software.pvt?igsh=MTQ2cTJwaXo1Ymo4dw==">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a
                href="https://wa.me/917258803502"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon"
                style={{ fontSize: '3rem', color: 'green' }} // Large and green
              >
                <FontAwesomeIcon icon={faWhatsapp} />
              </a>
            </div>
          </div>
        </div>

        {/* Legal Links */}
        <div className="footer-legal">
          <p>
            <a href="/terms-of-use">Terms of Use</a> |{" "}
            <a href="/privacy-policy">Privacy Policy</a> |{" "}
            <a href="/document/Privacy Policy.pdf">Razorpay Privacy Policy</a> |{" "}
            <a href="/document/Terms of Service.pdf">Razorpay Terms of Service</a>
          </p>
        </div>

        {/* Footer Bottom */}
        <div className="footer-bottom">
          <p>&copy; {new Date().getFullYear()} Encoders Software Private Limited. All rights reserved.</p>
          <p>Your IP: <strong>{ipInfo.ip}</strong>, Visit Count: <strong>{ipInfo.visitCount}</strong></p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
